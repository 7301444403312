<template>
  <div class="fake">
    <table class="fat-only">
      <tr>
        <th>
          {{sify('時間')}}
        </th>
        <th>
          {{sify('品種')}}
        </th>
        <!--<th>
          {{sify('買進/賣出')}}
        </th>-->
        <th>
          {{sify('價格')}}
        </th>
        <th>
          {{sify('數量')}}
        </th>
      </tr>
      <tr v-for="newLog in r(fakeLogs)" :key = "newLog.time" :class="{buy: newLog.action =='buy', sell: newLog.action == 'sell'}">
        <td>
          {{par(newLog.time)}}
        </td>
        <td>
          {{newLog.m}}
        </td>
        <!--<td>
          {{newLog.action == 'buy' ? sify('買進') : sify('賣出')}}
        </td>-->
        <td>
          {{pretty(newLog.price)}}
        </td>
        <td>
          {{newLog.number}}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import { sify } from 'chinese-conv'

export default {
  name: 'FakeData',
  props: ['cgp', 'csp', 'm', 'config'],
  methods: {
    sify (t) {
      return sify(t)
    },
    pretty (n) {
      const s = '' + n
      if (s.indexOf('.') > -1) {
        return s.split('.')[0] + '.' + s.split('.')[1].substr(0, 2)
      } else {
        return s
      }
    },
    par (t, isSimple) {
      function addP (n) {
        if (('' + n).split('').length === 1) {
          return '0' + n
        } else {
          return n
        }
      }
      if (isSimple) {
        return (new Date(t).getFullYear() + '-' + (new Date(t).getMonth() + 1) + '-' + new Date(t).getDate())
      } else {
        return (addP(new Date(t).getHours()) + ':' + addP(new Date(t).getMinutes()) + ':' + addP(new Date(t).getSeconds()))
      }
    },
    r (list) {
      const ans = [...list]
      ans.sort(function (a, b) {
        return b.time - a.time
      })
      return ans.slice(0, 10)
    },
    addLog () {
      if (new Date().getHours() >= this.config.end_hour) {
        return
      }
      if (new Date().getHours() < this.config.start_hour) {
        return
      }
      const m = this.m
      var o = {
        time: new Date().getTime() - Math.ceil(Math.random() * 1000 * 60 * 5),
        action: Math.random() > 0.5 ? 'buy' : 'sell',
        m: m,
        price: m === '黃金' ? this.cgp : this.csp,
        number: Math.ceil(Math.random() * 20)
      }
      this.fakeLogs.push(o)
      console.log('log added!')
    }
  },
  data () {
    return {
      fakeLogs: []
    }
  },
  watch: {
    m () {
      this.fakeLogs = []
      this.addLog()
      this.addLog()
      this.addLog()
    }
  },
  mounted () {
    this.addLog()
    this.addLog()
    this.addLog()
    setInterval(this.addLog, 20 * 1000)
  }
}
</script>

<style type="text/css" scoped>
  .fake {
    border-left: 3px solid #999;
    min-height: 500px;
    padding-left: 1em;
  }

  .ui.form .field {
    padding: 0 3em;
  }
  .ui.form .field .label {
    background-color: #111;
    color: white;
  }
  .red.list {
    position: relative;
    border: 2px outset red;
    border-radius: 30px;
    width: 88%;
    height: 200px;
    margin: 0 auto;
  }

  .l {
    text-align: left;
  }
  #all {
    position: absolute;
    bottom: 1em;
    right: 1em;
    /* text-decoration: underline; */
  }

  table {
    border-collapse: collapse;
  }

  th {
    min-width: 60px;
    border-bottom: 2px solid #999;
    color: #999;
  }

  .ll th {
    border-right: 2px solid #999;
    border-bottom: 1px solid #999;
  }

  .ll td {
    text-align: left;
    padding-left: .6em;
    border-bottom: 1px solid #999;
  }

  .buy {
    color: red;
  }

  .sell {
    color: green;
  }

  @media screen and (min-width: 1501px) {
    th, td {
      font-size: 18px;
    }
  }
</style>
