import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

// Get a Firestore instance
const fb = initializeApp({
  apiKey: 'AIzaSyBx47BSm9unDXYf3C2LoOKrPL6eBcYsx0E',
  authDomain: 'gold-a5614.firebaseapp.com',
  projectId: 'gold-a5614',
  storageBucket: 'gold-a5614.appspot.com',
  messagingSenderId: '449360489593',
  appId: '1:449360489593:web:75350edb0302ea342338a8',
  measurementId: 'G-0PEMETPQ3H',
  databaseURL: 'https://gold-a5614-default-rtdb.asia-southeast1.firebasedatabase.app/'
})

export const db = getDatabase(fb)

// console.log(db)

// export const db = getDatabase()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples

// if using Firebase JS SDK < 5.8.0
// db.settings({ timestampsInSnapshots: true })
