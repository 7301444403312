<template>
  <div class="home">
    <login :uid="uid" v-if="!uid" @logged = "logged" @createAccount = "createAccount" ></login>
    <div class="main" v-if="uid">
      <div class="ui segment container">
        <div class="ui grid">
          <div class="ui row">
            <div id = "mm" class="column">
              <!-- <h1>{{sify('行情圖表')}}</h1> -->
              <!-- <p v-show = "m == '黃金'">{{sify('目前黃金價格：')}}{{pretty(cgp) }}</p>
              <p v-show = "m == '白銀'">{{sify('目前白銀價格：')}}{{ pretty(csp) }}</p> -->
              <div id="bag">
                <iframe class="price" v-show = "m == '黃金'" src="/gold.html"></iframe>

                <iframe class="price" v-show = "m == '白銀'" src="/silver.html"></iframe>
              </div>
              <!-- <svg class="chart" width="300" height="300">
                <g v-show = "m == '黃金'">
                  <text x="0" y="0" fill="#999">14000</text>
                  <text x="0" y="50" fill="#999">13000</text>
                  <text x="0" y="100" fill="#999">12000</text>
                  <text x="0" y="150" fill="#999">11000</text>
                  <text x="0" y="200" fill="#999">10000</text>
                  <text x="0" y="250" fill="#999">9000</text>
                </g>
                <g v-show = "m == '白銀'">
                  <text x="0" y="0" fill="#999">300</text>
                  <text x="0" y="50" fill="#999">250</text>
                  <text x="0" y="100" fill="#999">200</text>
                  <text x="0" y="150" fill="#999">150</text>
                  <text x="0" y="200" fill="#999">100</text>
                  <text x="0" y="250" fill="#999">50</text>
                </g>
                <line v-for ="l in bglines" :key="l.id" :x1="l.x1" :y1="l.y1" :x2="l.x2" :y2="l.y2" :style="{stroke: l.color, 'stroke-width':1 }" />
                <g v-show = "mode == 'realtime'">
                  <g v-show = "m == '黃金'">
                    <circle v-for ="c in goldDotsRT" :key="c.id" :cx="c.cx" :cy="c.cy" r="3" stroke="black" stroke-width="0" fill="white" />
                    <polygon :points="gptsRT" style="fill:#aaa;stroke:white;stroke-width:2;opacity: 0.4;" />
                    <rect v-for="r in goldRectsRT" :key="r.id" :x="r.x" :y="r.y - r.h" width="10" :height="r.h" :style="{fill: r.c}" />
                  </g>
                  <g v-show = "m == '白銀'">
                    <circle v-for ="c in silverDotsRT" :key="c.id" :cx="c.cx" :cy="c.cy" r="3" stroke="black" stroke-width="0" fill="white" />
                    <polygon :points="sptsRT" style="fill:#aaa;stroke:white;stroke-width:2;opacity: 0.4;" />
                    <rect v-for="r in silverRectsRT" :key="r.id" :x="r.x" :y="r.y - r.h" width="10" :height="r.h" :style="{fill: r.c}" />
                  </g>
                </g>
                <g v-show = "mode == 'days'">
                  <g v-show = "m == '黃金'">
                    <circle v-for ="c in goldDots" :key="c.id" :cx="c.cx" :cy="c.cy" r="3" stroke="black" stroke-width="0" fill="white" />
                    <polygon :points="gpts" style="fill:#aaa;stroke:white;stroke-width:2;opacity: 0.4;" />
                    <rect v-for="r in goldRects" :key="r.id" :x="r.x" :y="r.y - r.h" width="10" :height="r.h" :style="{fill: r.c}" />
                  </g>
                  <g v-show = "m == '白銀'">
                    <circle v-for ="c in silverDots" :key="c.id" :cx="c.cx" :cy="c.cy" r="3" stroke="black" stroke-width="0" fill="white" />
                    <polygon :points="spts" style="fill:#aaa;stroke:white;stroke-width:2;opacity: 0.4;" />
                    <rect v-for="r in silverRects" :key="r.id" :x="r.x" :y="r.y - r.h" width="10" :height="r.h" :style="{fill: r.c}" />
                  </g>
                </g>
                <g v-show="mode == 'days'">
                  <text x="230" y="290" fill="#999">{{day(0)}}</text>
                  <text x="120" y="290" fill="#999">{{day(13)}}</text>
                  <text x="0" y="290" fill="#999">{{day(26)}}</text>
                </g>
              </svg> -->
            </div>
            <!-- <div class="four wide column fat-only">
                <table id="pTable" class="ui celled table">
                <thead>
                  <tr><th>{{sify('時間')}}</th>
                  <th>{{sify('價格')}}</th>
                </tr></thead>
                <tbody>
                  <tr v-show="m == '黃金'" v-for = "p in showHistory(goldHistory)" :key="p.time" :style="{color: p.delta > 0 ? 'red' : 'green'}">
                    <td>{{parseTime(p.time)}}</td>
                    <td>{{pretty(p.price)}}</td>
                  </tr>
                  <tr v-show="m == '白銀'" v-for = "p in showHistory(silverHistory)" :key="p.time" :style="{color: p.delta > 0 ? 'red' : 'green'}">
                    <td>{{parseTime(p.time)}}</td>
                    <td>{{pretty(p.price)}}</td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
        </div>
      </div>
      <div class="ui form">
        <div class="ui buttons">
          <a class="ui large red button" :class="{basic: action == 'sell'}" @click="action='buy'">{{sify('買進')}}</a>
          <a class="ui large green button" :class="{basic: action == 'buy'}" @click="action='sell'">{{sify('賣出')}}</a>
          <a id ="w" class="ui basic button" @click="toggleRecord()"><i class="bars icon"/>{{sify('訂單')}}</a>
          <sui-dropdown id ="w" class="ui basic button" :text="sify(acc == 'demo' ? '模擬帳戶' : '正式帳戶')" icon="chevron down icon">
            <sui-dropdown-menu>
              <sui-dropdown-item>
                <a @click="updateAcc('demo')">{{sify('模擬帳戶')}}</a>
              </sui-dropdown-item>
              <sui-dropdown-item>
                <a @click="updateAcc('formal')">{{sify('正式帳戶')}}</a>
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>
        </div>

        <div class="two fields">
          <div id = "rb" class="field">
            <div class="ui labeled input">
              <div class="ui label">{{sify('單位')}}</div>
              <input type="number" step="0.01" name="" v-model="number" :placeholder="sify('額度')" />
            </div>
            <div class="ui labeled input">
              <div class="ui label">{{sify('本金')}}</div>
              <input type="number" step="0.01" name="" v-model="useMoney" :placeholder="sify('額度')" />
            </div>
            <div class="ui list">
            <div class="item" v-show="action == 'sell' && m == '白銀'">{{sify('白銀餘額：')}} {{ pretty(user[acc].silver) }}</div>
            <div class="item" v-show="action == 'sell' && m == '黃金'">{{sify('黃金餘額：')}} {{ pretty(user[acc].gold) }}</div>
            </div>
          </div>
          <div id = "rm" class="field" v-if = "uid">
            <div class="ui list">
              <div class="item">{{sify('帳戶餘額：')}} {{ pretty(user[acc].money) }}</div>

              <div class="item">{{sify('當日盈虧：')}} {{ pretty(countToday()) }}</div>

              <div class="item">{{sify('持有盈虧：')}} {{ pretty(countAll()) }}</div>
            </div>
          </div>
        </div>
        <div class="field">
          <div id = "pre" class="ui four buttons">
            <a id="p" class="ui button" @click="use(10)">10%</a>
            <a id="p" class="ui button" @click="use(20)">20%</a>
            <a id="p" class="ui button" @click="use(50)">50%</a>
            <a id="p" class="ui button" @click="use(100)">100%</a>
          </div>
        </div>
        <div class="field">
          <a class="ui large red button" v-show="action == 'buy'" @click="buy()">{{sify('買進')}}{{number}}單位的{{m}}</a>
          <a class="ui large green button" v-show="action == 'sell'" @click="sell()">{{sify('賣出')}}{{number}}單位的{{m}}</a>
        </div>
        <div class="field" v-show="newLog && showLog">
          <record :newLog = "newLog"/>
        </div>
      </div>
    </div>
    <!-- <iframe id="d" src="https://finance.sina.com.cn/futuremarket" style="display: none"></iframe> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Record from '@/components/Record.vue'
import Login from '@/components/Login.vue'
import { sify } from 'chinese-conv'

export default {
  name: 'HomeView',
  props: ['m', 'uid', 'config', 'newLog', 'users', 'user', 'acc', 'cgp', 'csp', 'mode'],
  components: {
    Login,
    Record
  },
  metaInfo: {
    title: sify('交易')
  },
  methods: {
    updateAcc (acc) {
      this.$emit('updateAcc', acc)
    },
    countToday () {
      var b = 0
      // console.log(this.user[this.acc])
      const ls = (this.user[this.acc].logs || [])
      for (let i = 0; i < ls.length; i++) {
        const l = this.user[this.acc].logs[i]
        if (new Date(l.time).getDate() === new Date().getDate()) {
          b += parseFloat(l.benifit)
        }
      }
      if (b === 0) { return 0 } else {
        return b
      }
    },
    countAll () {
      var ans = 0
      ans += Math.round((this.cgp - this.config.delta) - ((this.cgp - this.config.delta) * this.config.fee) - this.countMeanPrice(this.uid, 'sell', this.cgp, this.config.delta, this.gold, this.config.fee, '黃金')) * 100 / 100
      ans += Math.round((this.csp - this.config.delta) - ((this.csp - this.config.delta) * this.config.fee) - this.countMeanPrice(this.uid, 'sell', this.csp, this.config.delta, this.silver, this.config.fee, '白銀')) * 100 / 100
      if (!ans) { ans = 0 }
      return ans
    },
    countMeanPrice (uid, action, price, delta, number, fee, m) {
      var p = 0
      var n = 0
      const ls = (this.users[uid][this.acc].logs || []).filter((o) => {
        return o.m === m
      })
      for (let i = 0; i < ls.length; i++) {
        const l = this.users[uid][this.acc].logs[i]
        if (l.action === 'buy' && l.price && l.delta) {
          p += (l.price + l.delta) * l.number
          n += (l.number - (l.number * l.fee))
        }
      }
      if (action === 'buy') {
        p += (price + delta) * number
        n += (number - (number * fee))
      }
      return p / n
    },
    parseTime (t) {
      const d = new Date(t)
      return d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
    },
    showHistory (list) {
      const pCopy = [...list]
      pCopy.reverse()
      return pCopy.slice(0, 10)
    },
    toggleRecord () {
      this.showLog = !this.showLog
    },
    day (n) {
      const number = (new Date()).getTime() - n * 24 * 60 * 60 * 1000
      const d = new Date(number)
      const ans = d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate()
      return ans
    },
    pretty (n) {
      if (('' + n).split('.')[1]) {
        return ('' + n).split('.')[0] + '.' + ('' + n).split('.')[1].substr(0, 2)
      } else {
        return ('' + n)
      }
    },
    use (n) {
      if (this.action === 'buy') {
        this.useMoney = this.user[this.acc].money * n / 100
      }
      if (this.action === 'sell') {
        if (this.m === '黃金') {
          this.number = this.user[this.acc].gold * n / 100
        }
        if (this.m === '白銀') {
          this.number = this.user[this.acc].silver * n / 100
        }
      }
    },
    par (t) {
      return (new Date(t).getFullYear() + '-' + (new Date(t).getMonth() + 1) + '-' + new Date(t).getDate() + ':' + new Date(t).getHours() + ':' + new Date(t).getMinutes() + ':' + new Date(t).getSeconds())
    },
    buy () {
      if (window.confirm(sify('確定要買進') + this.number + '單位的' + this.m + sify('嗎？(手續費') + parseFloat(this.config.fee) * 100 + '%)')) {
        const price = this.m === '黃金' ? this.cgp : this.csp
        this.$emit('buy', this.number, this.useMoney, price)
        this.number = 0
        this.useMoney = 0
      }
    },
    sell () {
      if (window.confirm(sify('確定要賣出') + this.number + '單位的' + this.m + sify('嗎？(手續費') + parseFloat(this.config.fee) * 100 + '%)')) {
        const price = this.m === '黃金' ? this.cgp : this.csp
        this.$emit('sell', this.number, this.useMoney, price)
        this.number = 0
        this.useMoney = 0
      }
    },
    sify (t) {
      return sify(t)
    },
    logged (uid, email) {
      this.$emit('logged', uid, email)
    },
    createAccount (uid, email) {
      this.$emit('createAccount', uid, email)
    },
    updateP1 () {
      console.log('try to fetch data')
      // const vm = this
      this.axios.get('https://tw.tradingview.com/markets/futures/quotes-metals/', {
        //      this.axios.get('https://proxy.cors.sh/https://www.stockq.org/commodity/COMMSILV.php', {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((data) => {
        console.log(data)
      })
    },
    updateCGP (n) {
      this.$emit('updateCGP', n)
    },
    updateCSP (n) {
      this.$emit('updateCSP', n)
    },
    updateP () {
      const vm = this
      this.axios.get('https://weathered-frog-1b81.ttsking5452.workers.dev/', {
        //      this.axios.get('https://proxy.cors.sh/https://www.stockq.org/commodity/COMMSILV.php', {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((data) => {
        console.log('GET silverPrice!')
        const arr = [...data.data.matchAll(/<td .*?center>\d\d\.\d\d.*?<\/td>/g)]
        const ps = arr.map((s) => {
          return ('' + s).replace(/^<td .*?center>/, '').replace(/<\/td>/, '')
        })
        vm.silverPrice = ps
        const ag = parseFloat(ps[0])
        vm.axios.get('https://api.exchangerate.host/latest').then((data) => {
          vm.updateCSP(ag / data.data.rates.USD * data.data.rates.CNY)

          vm.silverHistory.push(
            {
              time: new Date().getTime(),
              price: ag / data.data.rates.USD * data.data.rates.CNY,
              delta: (vm.silverHistory[0] === undefined) ? 0 : ag / data.data.rates.USD * data.data.rates.CNY - vm.silverHistory[vm.silverHistory.length - 1].price
            })

          vm.silverDotsRT = vm.silverHistory.map((o, idx) => {
            return {
              id: idx + 800,
              cx: idx * 150 / 10,
              cy: 300 - (parseFloat(o.price) - 50) / 2 * 3
            }
          })

          vm.silverRectsRT = vm.silverHistory.map((o, idx) => {
            return {
              id: idx + 1000,
              x: idx * 150 / 10 - 10,
              y: 300,
              c: o.delta > 0 ? 'red' : 'green',
              h: Math.abs(o.delta)
            }
          })

          vm.sptsRT = vm.silverDotsRT.map((o) => {
            return o.cx + ',' + o.cy
          }).join(' ') + ' ' + vm.silverDotsRT[vm.silverDotsRT.length - 1].cx + ',300 0,300'

          vm.silverPrice = vm.silverPrice.map((n) => {
            return n / data.data.rates.USD * data.data.rates.CNY
          })

          vm.silverDots = vm.silverPrice.map((n, idx) => {
            return {
              id: idx + 400,
              cx: 300 - idx * 150 / 10,
              cy: 300 - (parseFloat(n) - 50) / 2 * 3
            }
          })
          vm.silverRects = vm.silverPrice.map((n, idx) => {
            return {
              id: idx + 1000,
              x: 300 - idx * 150 / 10 - 10,
              y: 300,
              c: n > vm.silverPrice[idx + 1] ? 'red' : 'green',
              h: Math.abs(n - (vm.silverPrice[idx + 1] || n))
            }
          })
          vm.spts = vm.silverDots.map((o) => {
            return o.cx + ',' + o.cy
          }).join(' ') + '  0,300 300,300'
        })
      })
      this.axios.get('https://rough-glade-477e.ttsking5452.workers.dev', {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((data) => {
        console.log('GET goldPrice!')
        // console.log(data)
        const arr = [...data.data.matchAll(/<td.*?center>\d\d\d\d\.\d\d.*?<\/td>/g)]
        const ps = arr.map((s) => {
          return ('' + s).replace(/^<td .*?center>/, '').replace(/<\/td>/, '')
        })
        vm.goldPrice = ps
        const au = parseFloat(ps[0])
        vm.axios.get('https://api.exchangerate.host/latest').then((data) => {
          vm.updateCGP(au / data.data.rates.USD * data.data.rates.CNY)

          vm.goldHistory.push(
            {
              time: new Date().getTime(),
              price: au / data.data.rates.USD * data.data.rates.CNY,
              delta: (vm.goldHistory[0] === undefined) ? 0 : au / data.data.rates.USD * data.data.rates.CNY - vm.goldHistory[vm.goldHistory.length - 1].price
            })

          vm.goldDotsRT = vm.goldHistory.map((o, idx) => {
            return {
              id: idx + 800,
              cx: idx * 150 / 10,
              cy: 300 - (parseFloat(o.price) - 9000) / 50 * 3
            }
          })

          vm.goldRectsRT = vm.goldHistory.map((o, idx) => {
            return {
              id: idx + 1000,
              x: idx * 150 / 10 - 10,
              y: 300,
              c: o.delta > 0 ? 'red' : 'green',
              h: Math.abs(o.delta) / 10
            }
          })

          vm.gptsRT = vm.goldDotsRT.map((o) => {
            return o.cx + ',' + o.cy
          }).join(' ') + ' ' + vm.goldDotsRT[vm.goldDotsRT.length - 1].cx + ',300 0,300'

          vm.goldPrice = vm.goldPrice.map((n) => {
            return n / data.data.rates.USD * data.data.rates.CNY
          })

          // console.log(vm.goldPrice)
          vm.goldDots = vm.goldPrice.map((n, idx) => {
            return {
              id: idx + 800,
              cx: 300 - idx * 150 / 10,
              cy: 300 - (parseFloat(n) - 9000) / 50 * 3
            }
          })
          vm.goldRects = vm.goldPrice.map((n, idx) => {
            return {
              id: idx + 1000,
              x: 300 - idx * 150 / 10 - 10,
              y: 300,
              c: n > vm.goldPrice[idx + 1] ? 'red' : 'green',
              h: Math.abs(n - (vm.goldPrice[idx + 1] || n)) / 10
            }
          })
          vm.gpts = vm.goldDots.map((o) => {
            return o.cx + ',' + o.cy
          }).join(' ') + '  0,300 300,300'
        })
      })
    }
  },
  data () {
    return {
      history: [],
      number: 0,
      action: 'buy',
      goldPrice: null,
      silverPrice: null,
      showLog: true,
      useMoney: 0,
      goldHistory: [],
      silverHistory: [],
      bglines: [
        { id: 'b0', x1: 0, y1: 0, x2: 300, y2: 0, color: '#ccc' },
        { id: 'b1', x1: 0, y1: 50, x2: 300, y2: 50, color: '#ccc' },
        { id: 'b2', x1: 0, y1: 100, x2: 300, y2: 100, color: '#ccc' },
        { id: 'b3', x1: 0, y1: 150, x2: 300, y2: 150, color: '#ccc' },
        { id: 'b4', x1: 0, y1: 200, x2: 300, y2: 200, color: '#ccc' },
        { id: 'b5', x1: 0, y1: 250, x2: 300, y2: 250, color: '#ccc' }
      ],
      goldLines: [],
      goldDots: [],
      goldRects: [],
      goldDotsRT: [],
      goldRectsRT: [],
      gpts: '',
      gptsRT: '',
      silverLines: [],
      silverDots: [],
      silverRects: [],
      silverDotsRT: [],
      silverRectsRT: [],
      spts: '',
      sptsRT: '',
      lines: [
        { id: 0, x1: 0, y1: 0, x2: 2, y2: 2 },
        { id: 1, x1: 2, y1: 2, x2: 4, y2: 2 },
        { id: 2, x1: 4, y1: 2, x2: 6, y2: 3 },
        { id: 3, x1: 6, y1: 3, x2: 8, y2: 5 },
        { id: 4, x1: 8, y1: 5, x2: 10, y2: 6 },
        { id: 5, x1: 10, y1: 6, x2: 12, y2: 4 },
        { id: 6, x1: 12, y1: 4, x2: 14, y2: 6 },
        { id: 7, x1: 14, y1: 6, x2: 16, y2: 8 },
        { id: 8, x1: 16, y1: 8, x2: 18, y2: 9 },
        { id: 9, x1: 18, y1: 9, x2: 20, y2: 10 },
        { id: 10, x1: 20, y1: 10, x2: 22, y2: 8 },
        { id: 11, x1: 22, y1: 8, x2: 24, y2: 9 },
        { id: 12, x1: 24, y1: 9, x2: 26, y2: 10 },
        { id: 13, x1: 26, y1: 10, x2: 28, y2: 10 },
        { id: 14, x1: 28, y1: 10, x2: 30, y2: 11 },
        { id: 15, x1: 30, y1: 11, x2: 32, y2: 12 },
        { id: 16, x1: 32, y1: 12, x2: 34, y2: 14 },
        { id: 17, x1: 34, y1: 14, x2: 36, y2: 16 },
        { id: 18, x1: 36, y1: 16, x2: 38, y2: 18 },
        { id: 19, x1: 38, y1: 18, x2: 40, y2: 16 },
        { id: 20, x1: 40, y1: 16, x2: 42, y2: 15 },
        { id: 21, x1: 42, y1: 15, x2: 44, y2: 10 },
        { id: 22, x1: 44, y1: 10, x2: 46, y2: 8 },
        { id: 23, x1: 46, y1: 8, x2: 48, y2: 9 }
      ]
    }
  },
  watch: {
    m () {
      this.useMoney = 0
      this.number = 0
    },
    useMoney (newU) {
      if (this.action === 'buy') {
        if (newU > this.money) {
          this.useMoney = this.money
        }
        if (newU >= 0 && this.m === '白銀') {
          this.number = Math.floor((this.useMoney / (this.csp + this.config.delta)) * 100) / 100
        }
        if (newU >= 0 && this.m === '黃金') {
          this.number = Math.floor((this.useMoney / (this.cgp + this.config.delta)) * 100) / 100
        }
      }
      if (this.action === 'sell') {
        if (newU >= 0 && this.m === '白銀') {
          if (Math.floor(this.useMoney / this.csp) > this.user[this.acc].silver) {
            this.useMoney = Math.ceil(this.user[this.acc].silver * this.csp)
          }
          this.number = Math.ceil(this.useMoney / (this.csp - this.config.delta) * 100) / 100
        }
        if (newU >= 0 && this.m === '黃金') {
          if (Math.floor(this.useMoney / this.cgp) > this.user[this.acc].gold) {
            this.useMoney = Math.ceil(this.user[this.acc].gold * this.cgp)
          }
          this.number = Math.ceil(this.useMoney / (this.cgp - this.config.delta) * 100) / 100
        }
      }
    },
    number (newNum) {
      if (this.action === 'buy') {
        // console.log(this.config.delta)
        // console.log(parseFloat(this.csp) + this.config.delta)
        if (newNum >= 0 && this.m === '白銀') {
          if (newNum * this.csp > this.money) {
            this.number = Math.floor(this.money / this.csp)
          }
          this.useMoney = Math.ceil(this.number * (parseFloat(this.csp) + this.config.delta))
        }
        if (newNum >= 0 && this.m === '黃金') {
          if (newNum * this.cgp > this.money) {
            this.number = Math.floor(this.money / this.cgp)
          }
          this.useMoney = Math.ceil(this.number * (parseFloat(this.cgp) + this.config.delta))
        }
      }
      if (this.action === 'sell') {
        if (newNum >= 0 && this.m === '白銀') {
          if (newNum > this.user[this.acc].silver) {
            this.number = this.user[this.acc].silver
          }
          this.useMoney = Math.floor(this.number * (this.csp - this.config.delta))
        }
        if (newNum >= 0 && this.m === '黃金') {
          if (newNum > this.user[this.acc].gold) {
            this.number = this.user[this.acc].gold
          }
          this.useMoney = Math.floor(this.number * (this.cgp - this.config.delta))
        }
      }
    }
  },
  mounted () {
    const vm = this
    this.updateP()
    setInterval(this.updateP, 10 * 1000) // 60*60
    vm.silverPrice = [137.65742899855024, 139.2769281632391, 136.81247291262565, 137.65742899855024, 133.36223556176685, 138.85445012027677, 131.17943233979494, 138.43197207731447, 130.61612828251188, 136.2491688553426, 127.51795596745498, 138.2911460629937, 128.50373806770037, 138.713624105956, 127.79960799609651, 135.6858647980595, 126.10969582424732, 138.713624105956, 127.37712995313423, 136.67164689830486, 130.33447625387035, 139.84023222052213, 132.86934451164416]
    vm.silverDots = vm.silverPrice.map((n, idx) => {
      return {
        id: idx + 400,
        cx: 300 - idx * 150 / 10,
        cy: 300 - (parseFloat(n) - 50) / 2 * 3
      }
    })
    vm.spts = vm.silverDots.map((o) => {
      return o.cx + ',' + o.cy
    }).join(' ') + '  0,300 300,300'
    vm.silverRects = vm.silverPrice.map((n, idx) => {
      return {
        id: idx + 1000,
        x: 300 - idx * 150 / 10 - 10,
        y: 300,
        c: n > vm.silverPrice[idx + 1] ? 'red' : 'green',
        h: Math.abs(n - (vm.silverPrice[idx + 1] || n))
      }
    })
    vm.goldPrice = [11718.83678170267, 11794.882829435883, 11711.091350915027, 11718.83678170267, 12097.658760225533, 11774.463057359373, 12039.92009435402, 11792.066309149468, 12103.291800798364, 11730.102862848331, 11992.743379556561, 11814.598471440791, 12053.29856571449, 11807.557170724755, 12065.268776931758, 11732.919383134746, 11964.578176692408, 11956.128615833162, 12057.523346144115, 11990.630989341751, 12145.539605094596, 12149.060255452616, 12239.188904617906]
    vm.goldDots = vm.goldPrice.map((n, idx) => {
      return {
        id: idx + 800,
        cx: 300 - idx * 150 / 10,
        cy: 300 - (parseFloat(n) - 9000) / 50 * 3
      }
    })
    vm.gpts = vm.goldDots.map((o) => {
      return o.cx + ',' + o.cy
    }).join(' ') + '  0,300 300,300'
    vm.goldRects = vm.goldPrice.map((n, idx) => {
      return {
        id: idx + 1000,
        x: 300 - idx * 150 / 10 - 10,
        y: 300,
        c: n > vm.goldPrice[idx + 1] ? 'red' : 'green',
        h: Math.abs(n - (vm.goldPrice[idx + 1] || n)) / 10
      }
    })
  }
}
</script>

<style type="text/css" scoped>
  .home {
    background-color: #111;
    color: white;
  }
  .ui.form {
    background-color: #333;
  }
  .ui.segment {
    background-color: #222;
  }
  .ui.form .field {
    padding: 0 3em;
  }
  .ui.form .field .label {
    background-color: #111;
    color: white;
  }
  .ui.form .field .input input {
    background-color: #111;
    color: white;
  }
  #p {
    background-color: #111 !important;
    color: white;
  }
  #p:hover, #p:active, #p.active {
    border-color: #c33 !important;
    background-color: #911 !important;
  }
  .red.list {
    border: 2px outset red;
    border-radius: 30px;
    width: 88%;
    height: 300px;
    margin: 0 auto;
  }

  .chart {
    background-color: #222;
  }

  #w {
    color: #ccc !important;
    border: 1px solid #999 !important;
  }

  #w:hover, #w:active, #w.active {
    background-color: #999 !important;
  }

  #rb, #rm {
    width: 46% !important;
  }

  #rm {
    padding-top: .6em;
    text-align: left;
  }

  #rm .item {
    text-align: left;
  }

  .chart {
 -webkit-transform: scaleY(0.8);
    -moz-transform: scaleY(0.8);
     -ms-transform: scaleY(0.8);
      -o-transform: scaleY(0.8);
      transform: scaleY(0.8);
  }

  @media screen and (min-width: 701px) {
    .chart {
 -webkit-transform: scaleX(1.5), scaleY(0.8);
    -moz-transform: scaleX(1.5), scaleY(0.8);
     -ms-transform: scaleX(1.5), scaleY(0.8);
      -o-transform: scaleX(1.5), scaleY(0.8);
      transform: scaleX(1.5), scaleY(0.8);
    }
  }

  @media screen and (min-width: 901px) {
    .chart {
 -webkit-transform: scaleX(2), scaleY(0.8);
    -moz-transform: scaleX(2), scaleY(0.8);
     -ms-transform: scaleX(2), scaleY(0.8);
      -o-transform: scaleX(2), scaleY(0.8);
      transform: scaleX(2), scaleY(0.8);
    }
  }

  .l {
    text-align: left;
  }

  #pTable tr th, #pTable tr td{
    background-color: #333;
  }

  #pTable tr th {
    color: #666;
  }

  .price {
    width: 100%;
    height: 300px;
  }

  #bag {
    width: 100%;
    height: 180px;
    overflow: hidden;
  }

  @media screen and (max-width: 600px) {
    #mm {
      width: 100% !important;
    }
    .price {
      width: 100%;
      height: 300px;
    }

    #bag {
      width: 100%;
      height: 180px;
      overflow: hidden;
    }
  }

  #pre {
    border-top: 2px solid white;
  }
</style>
