<template>
  <div class="home">
    <login :uid="uid" :users="users" v-if="!uid" @logged = "logged" @createAccount = "createAccount" ></login>
    <!-- <intro v-if="!uid"></intro> -->
    <div class="main" v-if="uid && user && user.stop">
      <div class="ui error message">
        <div class="header">
          <router-link to = "/service">{{sify('您已被停權，暫時無法交易。請聯繫客服')}}</router-link>
        </div>
     </div>
    </div>
    <div class="main" v-if="uid && user && !user.stop && user.role !== 'admin'">
      <div class="ui segment" id="wra">
        <div class="ui grid">
            <div id = "mm" class="my_column">
              <svg class="chart" width="500" height="300">
                <rect v-for="r in bgRects" :key="r.id" :x="r.x" :y="r.y" width="500" :height="25" :style="{fill: r.c}" />
                <g v-show = "m == '黃金'">
                  <text x="0" y="0" fill="#999">1800</text>
                  <text x="0" y="50" fill="#999">1750</text>
                  <text x="0" y="100" fill="#999">1700</text>
                  <text x="0" y="150" fill="#999">1650</text>
                  <text x="0" y="200" fill="#999">1600</text>
                  <text x="0" y="250" fill="#999">1550</text>
                </g>
                <g v-show = "m == '白銀'">
                  <text x="0" y="0" fill="#999">21</text>
                  <text x="0" y="50" fill="#999">21.5</text>
                  <text x="0" y="100" fill="#999">21</text>
                  <text x="0" y="150" fill="#999">20.5</text>
                  <text x="0" y="200" fill="#999">20</text>
                  <text x="0" y="250" fill="#999">19.5</text>
                </g>
                <line v-for ="l in bglines" :key="l.id" :x1="l.x1" :y1="l.y1" :x2="l.x2" :y2="l.y2" :style="{stroke: l.color, 'stroke-width':0.5 }" />
                <g v-show = "mode == 'realtime'">
                  <g v-show = "m == '黃金'">
                    <circle v-for ="c in goldDotsRT" :key="c.id" :cx="c.cx" :cy="c.cy" r="1.5" stroke="black" stroke-width="0" fill="white" />
                    <polygon :points="gptsRT" style="fill:#88a;stroke:white;stroke-width:0;opacity: 0.6;" />
                  </g>
                  <g v-show = "m == '白銀'">
                    <circle v-for ="c in silverDotsRT" :key="c.id" :cx="c.cx" :cy="c.cy" r="1.5" stroke="black" stroke-width="0" fill="white" />
                    <polygon :points="sptsRT" style="fill:#88a;stroke:white;stroke-width:0;opacity: 0.6;" />
                  </g>
                </g>
                <g v-show = "mode == 'days'">
                  <g v-show = "m == '黃金'">
                    <circle v-for ="c in goldDots" :key="c.id" :cx="c.cx" :cy="c.cy" r="1.5" stroke="black" stroke-width="0" fill="white" />
                    <polygon :points="gpts" style="fill:#88a;stroke:white;stroke-width:0;opacity: 0.6;" />
                  </g>
                  <g v-show = "m == '白銀'">
                    <circle v-for ="c in silverDots" :key="c.id" :cx="c.cx" :cy="c.cy" r="1.5" stroke="black" stroke-width="0" fill="white" />
                    <polygon :points="spts" style="fill:#88a;stroke:white;stroke-width:0;opacity: 0.6;" />
                  </g>
                </g>
              </svg>
              <svg class="chart" width="500" height="100">
                <g v-show="mode == 'realtime'">
                  <text v-if="begin" :x="begin.x" :y="begin.y" fill="#ccc" v-show="begin.show">{{ showTime(begin.t) }}</text>
                  <text v-for="t in realTimes" :key="t.id" :x="t.x" :y="t.y" fill="#ccc" v-show="t.show">{{ showTime(t.t) }}</text>
                </g>
                <g v-show="mode == 'days'">
                  <text x="420" y="10" fill="#ccc">{{day(0)}}</text>
                  <text x="220" y="10" fill="#ccc">{{day(13)}}</text>
                  <text x="0" y="10" fill="#ccc">{{day(26)}}</text>
                </g>
                <g v-show = "mode == 'days'">
                  <g v-show = "m == '白銀'">
                    <rect v-for="r in silverRects" :key="r.id" :x="r.x" :y="r.y - r.h - 200" width="15" :height="r.h" :style="{fill: r.c}" />'
                  </g>
                  <g v-show  = "m == '黃金'">
                    <rect v-for="r in goldRects" :key="r.id" :x="r.x" :y="r.y - r.h - 200" width="15" :height="r.h" :style="{fill: r.c}" />
                  </g>
                </g>
                <g v-show = "mode ==  'realtime'">
                  <g v-show = "m == '白銀'">
                    <rect v-for="r in silverRectsRT" :key="r.id" :x="r.x" :y="r.y - r.h" width="15" :height="r.h" :style="{fill: r.c}" />
                  </g>
                  <g v-show = "m == '黃金'">
                    <rect v-for="r in goldRectsRT" :key="r.id" :x="r.x" :y="r.y - r.h" width="15" :height="r.h" :style="{fill: r.c}" />
                  </g>
                </g>
              </svg>
            </div>
            <div id="ff" class="my_column fat-only" v-if="goodTime()">
              <fake :cgp="cgp" :csp="csp" :m="m" :config="config"/>
            </div>
          </div>
      </div>
      <div class="ui form fat-only">
        <div class="two fields">
          <div class="left aligned big field">
            <div class="ui list">
              <a id ="w" class="ui basic button" @click="toggleRecord()"><i class="bars icon"/>{{sify('訂單')}}</a>
              <sui-dropdown id ="w"  direction="downward" class="ui basic button" :text="sify(acc == 'demo' ? '模擬帳戶' : '正式帳戶')" icon="chevron down icon">
                <sui-dropdown-menu>
                  <sui-dropdown-item>
                    <a @click="updateAcc('demo')">{{sify('模擬帳戶')}}</a>
                  </sui-dropdown-item>
                  <sui-dropdown-item>
                    <a @click="updateAcc('formal')">{{sify('正式帳戶')}}</a>
                  </sui-dropdown-item>
                </sui-dropdown-menu>
              </sui-dropdown>
              <a id="w" class="ui basic button" @click="flatall()"><i class="undo icon"/>{{sify('全部平倉')}}</a>
            </div>
            <div class="ui grid">
              <div class="ui two column row">
                <div class="ui left aligned column">
                  <div class="ui list">
                    <div class="item">{{sify('總權益：')}} {{ pretty(user[acc].money) }}</div>
                    <div class="item">{{sify('占用保證金：')}} 0</div>
                    <div class="item">{{sify('當日手續費：')}} {{ pretty(countTodayFee()) }}</div>
                  </div>
                </div>
                <div class="ui left aligned column">
                  <div class="ui list">
                    <div class="item">{{sify('帳戶餘額：')}} {{ pretty(user[acc].money) }}</div>
                    <div class="item" v-show="action == 'sell'">{{m == '黃金' ? sify('黃金餘額：') : sify('白銀餘額：')}} {{ m == '黃金' ? pretty(user[acc].gold) : pretty(user[acc].silver) }}</div>
                    <div class="item">{{sify('當日盈虧：')}} {{ pretty(countToday()) }}</div>
                    <div class="item">{{sify('持有盈虧：')}} {{ pretty(countAll()) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="big field">
            <div class="two fields">
              <div class="field ff">
                <p class="down">{{sify('買入價格')}}</p>
                <div class="dotted buy" v-show="m == '黃金'">{{cgp + config.deltaBuyGold}}</div>
                <div class="dotted buy" v-show="m == '白銀'">{{csp + config.deltaBuySilver}}</div>
                <p class="down">{{sify('買入數量')}}</p>
                <div class="ui labeled input">
                  <a class="ui label plus" @click="number -= 1; action = 'buy'"> - </a>
                  <input type="number" step="0.01" name="" v-model="number" :placeholder="sify('額度')" />
                  <a class="ui label minus" @click="number += 1; action = 'buy'"> + </a>
                </div>
                <div class="ui divided buttons">
                  <a class="ui buy button" @click="action = 'buy'; use(10)" :class="{active: lastUse == 10 & action == 'buy'}" style="'border-color': 'red'">10%</a>
                  <a class="ui buy button" @click="action = 'buy'; use(20)" :class="{active: lastUse == 20 & action == 'buy'}" style="'border-color': 'red'">20%</a>
                  <a class="ui buy button" @click="action = 'buy'; use(50)" :class="{active: lastUse == 50 && action  == 'buy'}" style="'border-color': 'red'">50%</a>
                  <a class="ui buy button" @click="action = 'buy'; use(100)" :class="{active: lastUse == 100 & action == 'buy'}" style="'border-color': 'red'">100%</a>
                </div>
                <a class="ui large red button" @click="action='buy'; buy()">{{sify('買進')}}{{m}}</a>
              </div>
              <div class="field ff">
                <p class="down">{{sify('買空價格')}}</p>
                <div class="dotted sell" v-show="m == '黃金'">{{cgp + config.deltaBuyGold}}</div>
                <div class="dotted sell" v-show="m == '白銀'">{{csp + config.deltaBuySilver}}</div>
                <p class="down">{{sify('買空數量')}}</p>
                <div class="ui labeled input">
                  <a class="ui label plus" @click="number2 -= 1; action = 'buynull'"> - </a>
                  <input type="number" step="0.01" name="" v-model="number2" :placeholder="sify('額度')" />
                  <a class="ui label minus" @click="number2 += 1; action = 'buynull'"> + </a>
                </div>
                <div class="ui divided buttons">
                  <a class="ui sell button" @click="action = 'buynull'; use2(10)" :class="{active: lastUse == 10 & action == 'buynull'}" style="'border-color': 'green'">10%</a>
                  <a class="ui sell button" @click="action = 'buynull'; use2(20)" :class="{active: lastUse == 20 & action == 'buynull'}" style="'border-color': 'green'">20%</a>
                  <a class="ui sell button" @click="action = 'buynull'; use2(50)" :class="{active: lastUse == 50 && action  == 'buynull'}" style="'border-color': 'green'">50%</a>
                  <a class="ui sell button" @click="action = 'buynull'; use2(100)" :class="{active: lastUse == 100 & action == 'buynull'}" style="'border-color': 'green'">100%</a>
                </div>
                <a class="ui large green button" @click="action='buynull'; buynull(number2)">{{sify('買空')}}{{m}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui fluid grid fat-only" v-if="showLog && user[acc].logs">
        <div class="ui checkbox">
          <input type="checkbox" name="newOnly" v-model="newOnly" />
          <label>{{sify('僅顯示未平倉交易')}}</label>
        </div>
        <record :newLog = "l" v-for="l in r(user[acc].logs)" :key="l.id" :user="user" :acc="acc" :uid="uid" :cgp="cgp" :csp="csp" :all="countAll()" @flat="flat"/>
      </div>
      <div class="ui form thin-only">
        <div class="ui buttons">
          <a class="ui large red button" :class="{basic: action == 'buynull'}" @click="action='buy'">{{sify('買進')}}</a>
          <a class="ui large green button" :class="{basic: action == 'buy'}" @click="action='buynull'">{{sify('買空')}}</a>
          <a id ="w" class="ui basic button" @click="toggleRecord()"><i class="bars icon"/>{{sify('訂單')}}</a>
          <sui-dropdown id ="w" class="ui basic button" :text="sify(acc == 'demo' ? '模擬帳戶' : '正式帳戶')" icon="chevron down icon">
            <sui-dropdown-menu>
              <sui-dropdown-item>
                <a @click="updateAcc('demo')">{{sify('模擬帳戶')}}</a>
              </sui-dropdown-item>
              <sui-dropdown-item>
                <a @click="updateAcc('formal')">{{sify('正式帳戶')}}</a>
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>
        </div>

        <div class="two fields">
          <div id = "rb" class="field">
            <div class="ui labeled input">
              <div class="ui label">{{sify('單位')}}</div>
              <input type="number" v-show="action=='buy'" step="0.01" name="" v-model="number" :placeholder="sify('額度')" />
              <input type="number" v-show="action=='buynull'" step="0.01" name="" v-model="number2" :placeholder="sify('額度')" />
            </div>
            <div class="ui labeled input">
              <div class="ui label">{{sify('本金')}}</div>
              <p class="ui label">{{useMoney}}</p>
            </div>
            <div class="ui list">
            <div class="left aligned item" v-show="action == 'sell' && m == '白銀'">{{sify('白銀餘額：')}} {{ pretty(user[acc].silver) }}</div>
            <div class="left aligned item" v-show="action == 'sell' && m == '黃金'">{{sify('黃金餘額：')}} {{ pretty(user[acc].gold) }}</div>
            </div>
          </div>
          <div id = "rm" class="field" v-if = "uid">
            <div class="ui list">
              <div class="item">{{sify('帳戶餘額：')}} {{ pretty(user[acc].money) }}</div>

              <div class="item">{{sify('當日盈虧：')}} {{ pretty(countToday()) }}</div>

              <div class="item">{{sify('持有盈虧：')}} {{ pretty(countAll()) }}</div>
            </div>
          </div>
        </div>
        <div id = "pre" class="thin-only" :class="{sell: action == 'sell' || action == 'buynull'}">
          <div class="ui buttons" v-show="action=='buy'">
            <a id="p" class="ui button" @click="use(10)" :class="{active: lastUse == 10}" :style="{'border-color' : action == 'buy' ? 'red' : 'green'}">10%</a>
            <a id="p" class="ui button" @click="use(20)" :class="{active: lastUse == 20}" :style="{'border-color' : action == 'buy' ? 'red' : 'green'}">20%</a>
            <a id="p" class="ui button" @click="use(50)" :class="{active: lastUse == 50}" :style="{'border-color' : action == 'buy' ? 'red' : 'green'}">50%</a>
            <a id="p" class="ui button" @click="use(100)" :class="{active: lastUse == 100}" :style="{'border-color' : action == 'buy' ? 'red' : 'green'}">100%</a>
          </div>

          <div class="ui buttons" v-show="action=='buynull'">
            <a id="p" class="ui button" @click="use2(10)" :class="{active: lastUse == 10}" :style="{'border-color' : action == 'buy' ? 'red' : 'green'}">10%</a>
            <a id="p" class="ui button" @click="use2(20)" :class="{active: lastUse == 20}" :style="{'border-color' : action == 'buy' ? 'red' : 'green'}">20%</a>
            <a id="p" class="ui button" @click="use2(50)" :class="{active: lastUse == 50}" :style="{'border-color' : action == 'buy' ? 'red' : 'green'}">50%</a>
            <a id="p" class="ui button" @click="use2(100)" :class="{active: lastUse == 100}" :style="{'border-color' : action == 'buy' ? 'red' : 'green'}">100%</a>
          </div>

          <a class="ui large red button" v-show="action == 'buy'" @click="buy()">{{sify('買進')}}{{number}}單位的{{m}}</a>
          <a class="ui large green button" v-show="action == 'buynull'" @click="buynull(number2)">{{sify('買空')}}{{number2}}單位的{{m}}</a>
        </div>
        <div class="field thin-only" v-if="showLog && user[acc].logs">
          <div class="ui checkbox">
            <input type="checkbox" name="newOnly" v-model="newOnly" />
            <label>{{sify('僅顯示未平倉交易')}}</label>
          </div>
          <record :newLog = "l" v-for="l in r(user[acc].logs)" :key="l.id" :user="user" :uid="uid" :acc="acc" :cgp="cgp" :csp="csp" :all="countAll()" @flat="flat"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Fake from '@/components/FakeData.vue'
import Record from '@/components/Record.vue'
import Login from '@/components/Login.vue'
// import Intro from '@/components/Intro.vue'
import { sify } from 'chinese-conv'
import mixin from '@/mixins/countAll.js'

export default {
  name: 'HomeView',
  props: ['m', 'uid', 'config', 'newLog', 'users', 'user', 'acc', 'cgp', 'csp', 'mode', 'levs'],
  mixins: [
    mixin
  ],
  components: {
    Fake,
    // Intro,
    Login,
    Record
  },
  metaInfo: {
    title: sify('交易')
  },
  methods: {
    flatall () {
      const ls = this.user[this.acc].logs.filter(
        function (l) {
          return !l.done
        })
      // console.log(ls)
      for (var i = 0; i < ls.length; i++) {
        this.flat(ls[i], this.acc, this.uid)
      }
    },
    flat (log, acc, uid) {
      if (!log.done) {
        this.$emit('flat', log, acc, uid)
      }
    },
    goodTime () {
      var ans = true
      if (new Date().getHours() < this.config.start_hour) {
        ans = false
      }
      if (new Date().getHours() >= this.config.end_hour) {
        ans = false
      }
      return ans
    },
    r (list) {
      var ans = [...list]
      if (this.newOnly) {
        ans = ans.filter(function (l) {
          return !l.done
        })
      }
      ans.reverse()
      return ans
    },
    updateAcc (acc) {
      this.$emit('updateAcc', acc)
    },
    parseTime (t) {
      const d = new Date(t)
      return d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
    },
    showHistory (list) {
      const pCopy = [...list]
      pCopy.reverse()
      return pCopy.slice(0, 10)
    },
    toggleRecord () {
      this.showLog = !this.showLog
    },
    day (n) {
      const number = (new Date()).getTime() - n * 24 * 60 * 60 * 1000
      const d = new Date(number)
      const ans = d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate()
      return ans
    },
    pretty (n) {
      if (('' + n).split('.')[1]) {
        return ('' + n).split('.')[0] + '.' + ('' + n).split('.')[1].substr(0, 2)
      } else {
        return ('' + n)
      }
    },
    use2 (n) {
      this.lastUse = n
      if (this.action === 'buynull') {
        const uMoney = this.user[this.acc].money * parseInt(n) / 100
        if (this.m === '黃金') {
          this.number2 = Math.floor((uMoney + (this.config.fee * uMoney)) / (this.cgp + this.config.deltaBuyGold) * 100) / 100
        }
        if (this.m === '白銀') {
          this.number2 = Math.floor((uMoney + (this.config.fee * uMoney)) / (this.csp + this.config.deltaBuySilver) * 100) / 100
        }
      }
    },
    use (n) {
      this.lastUse = n
      if (this.action === 'buy') {
        const uMoney = this.user[this.acc].money * parseInt(n) / 100
        if (this.m === '黃金') {
          this.number = Math.floor((uMoney + (this.config.fee * uMoney)) / (this.cgp + this.config.deltaBuyGold) * 100) / 100
        }
        if (this.m === '白銀') {
          this.number = Math.floor((uMoney + (this.config.fee * uMoney)) / (this.csp + this.config.deltaBuySilver) * 100) / 100
        }
      }
      if (this.action === 'sell') {
        if (this.m === '黃金') {
          this.number = this.user[this.acc].gold * n / 100
        }
        if (this.m === '白銀') {
          this.number = this.user[this.acc].silver * n / 100
        }
      }
    },
    par (t, isSimple) {
      function addP (n) {
        if (('' + n).split('').length === 1) {
          return '0' + n
        } else {
          return n
        }
      }
      if (isSimple) {
        return (new Date(t).getFullYear() + '-' + (new Date(t).getMonth() + 1) + '-' + new Date(t).getDate())
      } else {
        return (addP(new Date(t).getHours()) + ':' + addP(new Date(t).getMinutes()) + ':' + addP(new Date(t).getSeconds()))
      }
    },
    buy () {
      if (new Date().getHours() < this.config.start_hour) {
        window.alert(sify('今日尚未開盤，請' + this.config.start_hour + '點後再來'))
        return
      }
      if (new Date().getHours() >= this.config.end_hour) {
        window.alert(sify('今日已收開盤，請於明日' + this.config.end_hour + '前過來'))
        return
      }
      if (this.user[this.acc].logs && this.user[this.acc].logs.filter(function (l) { return new Date(l.time).getDate() === new Date().getDate() }).length >= this.levs[this.user.lev].maxwork) {
        window.alert(sify('您已到達今日交易次數上限，請明天再來'))
        return
      }
      if (window.confirm(sify('確定要買進') + this.number + '單位的' + this.m + sify('嗎？(手續費') + parseFloat(this.config.fee) * 100 + '%)')) {
        const price = this.m === '黃金' ? this.cgp : this.csp
        this.$emit('buy', this.number, this.useMoney, price)
        this.number = 0
        this.useMoney = 0
        this.showLog = true
      }
    },
    buynull (number) {
      if (new Date().getHours() < this.config.start_hour) {
        window.alert(sify('今日尚未開盤，請' + this.config.start_hour + '點後再來'))
        return
      }
      if (new Date().getHours() >= this.config.end_hour) {
        window.alert(sify('今日已收開盤，請於明日' + this.config.end_hour + '前過來'))
        return
      }
      if (this.user[this.acc].logs && this.user[this.acc].logs.filter(function (l) { return new Date(l.time).getDate() === new Date().getDate() }).length >= this.levs[this.user.lev].maxwork) {
        window.alert(sify('您已到達今日交易次數上限，請明天再來'))
        return
      }
      if (window.confirm(sify('確定要買空') + number + '單位的' + this.m + sify('嗎？(手續費') + parseFloat(this.config.fee) * 100 + '%)')) {
        const price = this.m === '黃金' ? this.cgp : this.csp
        console.log(number)
        this.$emit('buynull', number, this.useMoney, price)
        this.number = 0
        this.number2 = 0
        this.useMoney = 0
        this.showLog = true
      }
    },
    sell (num) {
      if (new Date().getHours() < this.config.start_hour) {
        window.alert(sify('今日尚未開盤，請' + this.config.start_hour + '點後再來'))
        return
      }
      if (new Date().getHours() >= this.config.end_hour) {
        window.alert(sify('今日已收開盤，請於明日' + this.config.end_hour + '前過來'))
        return
      }
      if (this.user[this.acc].logs && this.user[this.acc].logs.filter(function (l) { return new Date(l.time).getDate() === new Date().getDate() }).length >= this.levs[this.user.lev].maxwork) {
        window.alert(sify('您已到達今日交易次數上限，請明天再來'))
        return
      }
      if (this.m === '白銀') {
        if (num > this.user[this.acc].silver) {
          this.number = 0
          this.number2 = 0
        }
        window.alert(sify('您沒有這多的白銀'))
        return
      }
      if (this.m === '黃金') {
        if (num > this.user[this.acc].gold) {
          this.number = 0
          this.number2 = 0
        }
        window.alert(sify('您沒有這多的黃金'))
        return
      }
      if (window.confirm(sify('確定要賣出') + num + '單位的' + this.m + sify('嗎？(手續費') + parseFloat(this.config.fee) * 100 + '%)')) {
        const price = this.m === '黃金' ? this.cgp : this.csp
        this.$emit('sell', num, this.useMoney, price)
        this.number = 0
        this.number2 = 0
        this.useMoney = 0
        this.showLog = true
      }
    },
    sellnull (num, oldPrice) {
      if (new Date().getHours() < this.config.start_hour) {
        window.alert(sify('今日尚未開盤，請' + this.config.start_hour + '點後再來'))
        return
      }
      if (new Date().getHours() >= this.config.end_hour) {
        window.alert(sify('今日已收開盤，請於明日' + this.config.end_hour + '前過來'))
        return
      }
      if (this.user[this.acc].logs && this.user[this.acc].logs.filter(function (l) { return new Date(l.time).getDate() === new Date().getDate() }).length >= this.levs[this.user.lev].maxwork) {
        window.alert(sify('您已到達今日交易次數上限，請明天再來'))
        return
      }
      if (this.m === '白銀') {
        if (num > this.user[this.acc].silver) {
          this.number = 0
          this.number2 = 0
        }
        window.alert(sify('您沒有這多的白銀'))
        return
      }
      if (this.m === '黃金') {
        if (num > this.user[this.acc].gold) {
          this.number = 0
          this.number2 = 0
        }
        window.alert(sify('您沒有這多的黃金'))
        return
      }
      if (window.confirm(sify('確定要賣出') + num + '單位的' + this.m + sify('嗎？(手續費') + parseFloat(this.config.fee) * 100 + '%)')) {
        const price = this.m === '黃金' ? this.cgp : this.csp
        this.$emit('sellnull', num, this.useMoney, price, oldPrice)
        this.number = 0
        this.number2 = 0
        this.useMoney = 0
        this.showLog = true
      }
    },
    sify (t) {
      return sify(t)
    },
    logged (uid, email) {
      this.$emit('logged', uid, email)
    },
    createAccount (uid, email) {
      this.$emit('createAccount', uid, email)
    },
    showTime (t) {
      let ans = ''
      ans += new Date(t).getHours() + ':' + new Date(t).getMinutes() + ':' + new Date(t).getSeconds()
      return ans
    },
    isWide () {
      // console.log(window.innerWidth)
      return window.innerWidth > 1100
    },
    isW () {
      this.isWide()
      this.$forceUpdate()
    },
    updateP1 () {
      console.log('try to fetch data')
      // const vm = this
      this.axios.get('https://tw.tradingview.com/markets/futures/quotes-metals/', {
        //      this.axios.get('https://proxy.cors.sh/https://www.stockq.org/commodity/COMMSILV.php', {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((data) => {
        console.log(data)
      })
    },
    updateCGP (n) {
      this.$emit('updateCGP', n)
      // console.log(this.cgp)
    },
    updateCSP (n) {
      this.$emit('updateCSP', n)
    },
    updateTime (t) {
      this.$emit('updateTime', t)
    },
    updateP () {
      const vm = this
      this.axios.get('https://weathered-frog-1b81.ttsking5452.workers.dev/', {
        //      this.axios.get('https://proxy.cors.sh/https://www.stockq.org/commodity/COMMSILV.php', {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((data) => {
        console.log('GET silverPrice!')

        // const arr = data.data.slice(-21).map(function (o) {
        //  return parseFloat(o[1])
        // })
        // vm.silverPrice = arr

        // console.log(vm.silverPrice.join(', '))
        // const ag = parseFloat(arr[arr.length - 1])
        // console.log(ag)

        const arr = [...data.data.matchAll(/<td .*?center>\d\d\.\d\d.*?<\/td>/g)]
        const ps = arr.map((s) => {
          return ('' + s).replace(/^<td .*?center>/, '').replace(/<\/td>/, '')
        })
        vm.silverPrice = ps
        // console.log(vm.silverPrice.join(', '))
        const ag = parseFloat(ps[0])
        // vm.axios.get('https://api.exchangerate.host/latest').then((data) => {
        vm.updateCSP(ag)

        vm.silverHistory.push(
          {
            time: new Date().getTime(),
            price: ag,
            delta: (vm.silverHistory[0] === undefined) ? 0 : ag - vm.silverHistory[vm.silverHistory.length - 1].price
          })

        vm.silverHistory = vm.silverHistory.slice(-22)

        vm.silverDotsRT = vm.silverHistory.map((o, idx) => {
          return {
            t: o.time,
            id: idx + 800,
            cx: idx * 150 / 10 / 3 * 5,
            cy: 305 - (parseFloat(o.price) - 15) * 50
          }
        })

        vm.realTimes = vm.silverDotsRT.map((o, idx) => {
          return {
            id: idx + 100000,
            x: o.cx,
            y: 50,
            t: o.t,
            show: (idx % 6 === (vm.silverDotsRT.length - 1) % 6) || !vm.silverDotsRT[0]
          }
        })

        vm.begin = {
          id: 200000,
          x: 0,
          y: 10,
          t: vm.silverDotsRT[0].t,
          show: vm.silverDotsRT.length > 1
        }

        vm.silverRectsRT = vm.silverHistory.map((o, idx) => {
          return {
            id: idx + 1000,
            x: idx * 150 / 10 / 3 * 5 - 10,
            y: 300,
            c: o.delta > 0 ? '#b33' : '#3b3',
            h: Math.abs(o.delta) * 50
          }
        })

        vm.sptsRT = vm.silverDotsRT.map((o) => {
          return o.cx + ',' + o.cy
        }).join(' ') + ' ' + vm.silverDotsRT[vm.silverDotsRT.length - 1].cx + ',300 0,300'

        // vm.silverPrice = vm.silverPrice.map((n) => {
        //  return n / data.data.rates.USD * data.data.rates.CNY
        // })

        vm.silverDots = vm.silverPrice.map((n, idx) => {
          return {
            id: idx + 400,
            cx: 500 - idx * 150 / 10 / 3 * 5,
            cy: 305 - (parseFloat(n) - 17) * 50
          }
        })

        vm.silverRects = vm.silverPrice.map((n, idx) => {
          return {
            id: idx + 1000,
            x: 500 - idx * 150 / 10 / 3 * 5 - 10,
            y: 280,
            c: n > vm.silverPrice[idx + 1] ? '#b33' : '#3b3',
            h: Math.abs(n - (vm.silverPrice[idx + 1] || n)) * 50
          }
        })

        vm.spts = vm.silverDots.map((o) => {
          return o.cx + ',' + o.cy
        }).join(' ') + ' 0,300 500,300'

        this.updateTime(new Date().getTime())
        // })
      })
      this.axios.get('https://rough-glade-477e.ttsking5452.workers.dev', {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((data) => {
        console.log('GET goldPrice!')
        // console.log(data)
        // const arr = data.data.slice(-21).map(function (o) {
        // console.log(o)
        //  return parseFloat(o[1])
        // })
        // const au = parseFloat(data.split(',')[2])
        const arr = [...data.data.matchAll(/<td.*?center>\d\d\d\d\.\d\d.*?<\/td>/g)]
        const ps = arr.map((s) => {
          return ('' + s).replace(/^<td .*?center>/, '').replace(/<\/td>/, '')
        })
        // vm.goldPrice = arr
        vm.goldPrice = ps
        // console.log(vm.goldPrice.join(', '))
        const au = parseFloat(ps[0])
        // vm.axios.get('https://api.exchangerate.host/latest').then((data) => {
        vm.updateCGP(au)
        // vm.updateCGP(au / data.data.rates.USD * data.data.rates.CNY)

        vm.goldHistory.push(
          {
            time: new Date().getTime(),
            price: au,
            // price: au / data.data.rates.USD * data.data.rates.CNY,
            delta: (vm.goldHistory[0] === undefined) ? 0 : au - vm.goldHistory[vm.goldHistory.length - 1].price
            // delta: (vm.goldHistory[0] === undefined) ? 0 : au / data.data.rates.USD * data.data.rates.CNY - vm.goldHistory[vm.goldHistory.length - 1].price
          })

        vm.goldHistory = vm.goldHistory.slice(-22)

        vm.goldDotsRT = vm.goldHistory.map((o, idx) => {
          return {
            id: idx + 800,
            cx: idx * 150 / 10 / 3 * 5,
            cy: 310 - (parseFloat(o.price) - 1500)
          }
        })

        vm.goldRectsRT = vm.goldHistory.map((o, idx) => {
          return {
            id: idx + 1000,
            x: idx * 150 / 10 / 3 * 5 - 10,
            y: 300,
            c: o.delta > 0 ? '#b33' : '#3b3',
            h: Math.abs(o.delta)
          }
        })

        vm.gptsRT = vm.goldDotsRT.map((o) => {
          return o.cx + ',' + o.cy
        }).join(' ') + ' ' + vm.goldDotsRT[vm.goldDotsRT.length - 1].cx + ',300 0,300'

        // vm.goldPrice = vm.goldPrice.map((n) => {
        //  return n / data.data.rates.USD * data.data.rates.CNY
        // })

        // console.log(vm.goldPrice)
        vm.goldDots = vm.goldPrice.map((n, idx) => {
          return {
            id: idx + 800,
            cx: 500 - idx * 150 / 10 / 3 * 5,
            cy: 310 - (parseFloat(n) - 1500)
          }
        })

        vm.goldRects = vm.goldPrice.map((n, idx) => {
          return {
            id: idx + 1000,
            x: 500 - idx * 150 / 10 / 3 * 5 - 10,
            y: 280,
            c: n > vm.goldPrice[idx + 1] ? '#b33' : '#3b3',
            h: Math.abs(n - (vm.goldPrice[idx + 1] || n))
          }
        })
        vm.gpts = vm.goldDots.map((o) => {
          return o.cx + ',' + o.cy
        }).join(' ') + ' 0,300 500,300'
        // })
      })
    }
  },
  data () {
    return {
      newOnly: false,
      warn: false,
      begin: null,
      realTimes: [],
      lastUse: 0,
      history: [],
      number: 0,
      number2: 0,
      action: 'buy',
      goldPrice: null,
      silverPrice: null,
      showLog: false,
      useMoney: 0,
      goldHistory: [],
      silverHistory: [],
      bgRects: [
        { id: 'r0', x: 0, y: 0, c: '#333' },
        { id: 'r1', x: 0, y: 50, c: '#333' },
        { id: 'r2', x: 0, y: 100, c: '#333' },
        { id: 'r3', x: 0, y: 150, c: '#333' },
        { id: 'r4', x: 0, y: 200, c: '#333' },
        { id: 'r5', x: 0, y: 250, c: '#333' }
      ],
      bglines: [
        { id: 'b0', x1: 0, y1: 0, x2: 500, y2: 0, color: '#ccc' },
        { id: 'b0.5', x1: 0, y1: 25, x2: 500, y2: 25, color: '#ccc' },
        { id: 'b1', x1: 0, y1: 50, x2: 500, y2: 50, color: '#ccc' },
        { id: 'b1.5', x1: 0, y1: 75, x2: 500, y2: 75, color: '#ccc' },
        { id: 'b2', x1: 0, y1: 100, x2: 500, y2: 100, color: '#ccc' },
        { id: 'b2.5', x1: 0, y1: 125, x2: 500, y2: 125, color: '#ccc' },
        { id: 'b3', x1: 0, y1: 150, x2: 500, y2: 150, color: '#ccc' },
        { id: 'b3.5', x1: 0, y1: 175, x2: 500, y2: 175, color: '#ccc' },
        { id: 'b4', x1: 0, y1: 200, x2: 500, y2: 200, color: '#ccc' },
        { id: 'b4.5', x1: 0, y1: 225, x2: 500, y2: 225, color: '#ccc' },
        { id: 'b5', x1: 0, y1: 250, x2: 500, y2: 250, color: '#ccc' },
        { id: 'b5.5', x1: 0, y1: 275, x2: 500, y2: 275, color: '#ccc' }
      ],
      goldLines: [],
      goldDots: [],
      goldRects: [],
      goldDotsRT: [],
      goldRectsRT: [],
      gpts: '',
      gptsRT: '',
      silverLines: [],
      silverDots: [],
      silverRects: [],
      silverDotsRT: [],
      silverRectsRT: [],
      spts: '',
      sptsRT: '',
      lines: [
        { id: 0, x1: 0, y1: 0, x2: 2, y2: 2 },
        { id: 1, x1: 2, y1: 2, x2: 4, y2: 2 },
        { id: 2, x1: 4, y1: 2, x2: 6, y2: 3 },
        { id: 3, x1: 6, y1: 3, x2: 8, y2: 5 },
        { id: 4, x1: 8, y1: 5, x2: 10, y2: 6 },
        { id: 5, x1: 10, y1: 6, x2: 12, y2: 4 },
        { id: 6, x1: 12, y1: 4, x2: 14, y2: 6 },
        { id: 7, x1: 14, y1: 6, x2: 16, y2: 8 },
        { id: 8, x1: 16, y1: 8, x2: 18, y2: 9 },
        { id: 9, x1: 18, y1: 9, x2: 20, y2: 10 },
        { id: 10, x1: 20, y1: 10, x2: 22, y2: 8 },
        { id: 11, x1: 22, y1: 8, x2: 24, y2: 9 },
        { id: 12, x1: 24, y1: 9, x2: 26, y2: 10 },
        { id: 13, x1: 26, y1: 10, x2: 28, y2: 10 },
        { id: 14, x1: 28, y1: 10, x2: 30, y2: 11 },
        { id: 15, x1: 30, y1: 11, x2: 32, y2: 12 },
        { id: 16, x1: 32, y1: 12, x2: 34, y2: 14 },
        { id: 17, x1: 34, y1: 14, x2: 36, y2: 16 },
        { id: 18, x1: 36, y1: 16, x2: 38, y2: 18 },
        { id: 19, x1: 38, y1: 18, x2: 40, y2: 16 },
        { id: 20, x1: 40, y1: 16, x2: 42, y2: 15 },
        { id: 21, x1: 42, y1: 15, x2: 44, y2: 10 },
        { id: 22, x1: 44, y1: 10, x2: 46, y2: 8 },
        { id: 23, x1: 46, y1: 8, x2: 48, y2: 9 }
      ]
    }
  },
  watch: {
    m () {
      this.useMoney = 0
      this.number = 0
      this.number2 = 0
      this.lastUse = 0
    },
    action () {
      this.useMoney = 0
      this.number = 0
      this.number2 = 0
      this.lastUse = 0
    },
    /* useMoney (newU) {
      if (this.action === 'buy') {
        if (newU > this.user[this.acc].money) {
          this.useMoney = this.user[this.acc]
        }
        if (newU >= 0 && this.m === '白銀') {
          this.number = Math.round((this.useMoney / (this.csp + this.config.delta)) * 100) / 100
        }
        if (newU >= 0 && this.m === '黃金') {
          this.number = Math.round((this.useMoney / (this.cgp + this.config.delta)) * 100) / 100
        }
        console.log(this.levs[this.user.lev].oncelim)
        if (newU > this.levs[this.user.lev].oncelim) {
          this.useMoney = parseInt(this.levs[this.users.lev].oncelim)
        }
        var tdTotal = 0
        if (this.user[this.acc].logs) {
          for (var i = 0; i < this.user[this.acc].logs.length; i++) {
            const l = this.user[this.acc].logs[i]
            if (new Date(l.time).getDate() === new Date().getDate()) {
              tdTotal += l.useMoney || 0
            }
          }
        }
        if (newU + tdTotal > parseInt(this.levs[this.user.lev].daylim)) {
          this.useMoney = parseInt(this.levs[this.users.lev].daylim) - tdTotal
        }
      }
      if (this.action === 'sell') {
        if (newU >= 0 && this.m === '白銀') {
          if (Math.floor(this.useMoney / this.csp) > this.user[this.acc].silver) {
            this.useMoney = Math.ceil(this.user[this.acc].silver * this.csp)
          }
          this.number = Math.ceil(this.useMoney / (this.csp - this.config.delta) * 100) / 100
        }
        if (newU >= 0 && this.m === '黃金') {
          if (Math.floor(this.useMoney / this.cgp) > this.user[this.acc].gold) {
            this.useMoney = Math.ceil(this.user[this.acc].gold * this.cgp)
          }
          this.number = Math.ceil(this.useMoney / (this.cgp - this.config.delta) * 100) / 100
        }
      }
    }, */
    number (newNum) {
      if (newNum < 0) {
        this.number = 0
      }
      var tdTotal = 0
      if (this.action === 'buy' || this.action === 'buynull') {
        // console.log(this.config.delta)
        // console.log(parseFloat(this.csp) + this.config.delta)
        if (newNum >= 0 && this.m === '白銀') {
          const newU = Math.ceil((this.number * (parseFloat(this.csp) + this.config.deltaBuySilver)) * 100) / 100

          if (newU > this.user[this.acc].money) {
            this.number = Math.floor(this.user[this.acc].money / this.csp)
          }

          if (newU > this.levs[this.user.lev].oncelim) {
            this.number = Math.floor((this.levs[this.user.lev].oncelim / this.csp) * 100) / 100
            if (!this.warn) {
              window.alert(sify('您目前等級的單筆交易上限為：') + this.levs[this.user.lev].oncelim)
              this.warn = true
            }
          }

          tdTotal = 0
          if (this.user[this.acc].logs) {
            for (var i = 0; i < this.user[this.acc].logs.length; i++) {
              const l = this.user[this.acc].logs[i]
              if (new Date(l.time).getDate() === new Date().getDate()) {
                tdTotal += l.useMoney || 0
              }
            }
          }

          if (newU + tdTotal > parseInt(this.levs[this.user.lev].daylim)) {
            this.number = (parseInt(this.levs[this.user.lev].daylim) - tdTotal) / this.csp
            if (!this.warn) {
              window.alert(sify('您目前等級的每日交易上限為：') + this.levs[this.user.lev].daylim)
              this.warn = true
            }
          }
          this.useMoney = newU
        }

        if (newNum >= 0 && this.m === '黃金') {
          const newU = Math.ceil((this.number * (parseFloat(this.cgp) + this.config.deltaBuyGold)) * 100) / 100

          if (newU > this.user[this.acc].money) {
            this.number = Math.floor(this.user[this.acc].money / this.cgp)
          }

          if (newU > this.levs[this.user.lev].oncelim) {
            this.number = Math.floor((this.levs[this.user.lev].oncelim / this.cgp) * 100) / 100
            if (!this.warn) {
              window.alert(sify('您目前等級的單筆交易上限為：') + this.levs[this.user.lev].oncelim)
              this.warn = true
            }
          }

          tdTotal = 0
          if (this.user[this.acc].logs) {
            for (var j = 0; j < this.user[this.acc].logs.length; j++) {
              const l = this.user[this.acc].logs[j]
              if (new Date(l.time).getDate() === new Date().getDate()) {
                tdTotal += l.useMoney || 0
              }
            }
          }

          if (newU + tdTotal > parseInt(this.levs[this.user.lev].daylim)) {
            this.number = (parseInt(this.levs[this.user.lev].daylim) - tdTotal) / this.cgp
            if (!this.warn) {
              window.alert(sify('您目前等級的每日交易上限為：') + this.levs[this.user.lev].daylim)
              this.warn = true
            }
          }
          this.useMoney = newU
        }
      }
      if (this.action === 'sell') {
        if (newNum >= 0 && this.m === '白銀') {
          if (newNum > this.user[this.acc].silver) {
            this.number = this.user[this.acc].silver
          }
          this.useMoney = Math.floor((this.number * (this.csp - this.config.deltaSellSilver)) * 100) / 100
        }
        if (newNum >= 0 && this.m === '黃金') {
          if (newNum > this.user[this.acc].gold) {
            this.number = this.user[this.acc].gold
          }
          this.useMoney = Math.floor((this.number * (this.cgp - this.config.deltaSellGold)) * 100) / 100
        }
      }
    },
    number2 (newNum) {
      if (newNum < 0) {
        this.number2 = 0
      }
      var tdTotal = 0
      if (this.action === 'buy' || this.action === 'buynull') {
        // console.log(this.config.delta)
        // console.log(parseFloat(this.csp) + this.config.delta)
        if (newNum >= 0 && this.m === '白銀') {
          const newU = Math.ceil((this.number2 * (parseFloat(this.csp) + this.config.deltaBuySilver)) * 100) / 100

          if (newU > this.user[this.acc].money) {
            this.number2 = Math.floor((this.user[this.acc].money / this.csp) * 100) / 100
          }

          if (newU > this.levs[this.user.lev].oncelim) {
            this.number2 = 0
            if (!this.warn) {
              window.alert(sify('您目前等級的單筆交易上限為：') + this.levs[this.user.lev].oncelim)
              this.warn = true
            }
          }

          tdTotal = 0
          if (this.user[this.acc].logs) {
            for (var i = 0; i < this.user[this.acc].logs.length; i++) {
              const l = this.user[this.acc].logs[i]
              if (new Date(l.time).getDate() === new Date().getDate()) {
                tdTotal += l.useMoney || 0
              }
            }
          }

          if (newU + tdTotal > parseInt(this.levs[this.user.lev].daylim)) {
            this.number2 = (parseInt(this.levs[this.user.lev].daylim) - tdTotal) / this.csp
            if (!this.warn) {
              window.alert(sify('您目前等級的每日交易上限為：') + this.levs[this.user.lev].daylim)
              this.warn = true
            }
          }
          this.useMoney = newU
        }

        if (newNum >= 0 && this.m === '黃金') {
          const newU = Math.ceil((this.number2 * (parseFloat(this.cgp) + this.config.deltaBuyGold)) * 100) / 100

          console.log(newU)

          if (newU > this.user[this.acc].money) {
            this.number2 = Math.floor((this.user[this.acc].money / this.cgp) * 100) / 100
          }

          if (newU > this.levs[this.user.lev].oncelim) {
            this.number2 = 0
            if (!this.warn) {
              window.alert(sify('您目前等級的單筆交易上限為：') + this.levs[this.user.lev].oncelim)
              this.warn = true
            }
          }

          tdTotal = 0
          if (this.user[this.acc].logs) {
            for (var j = 0; j < this.user[this.acc].logs.length; j++) {
              const l = this.user[this.acc].logs[j]
              if (new Date(l.time).getDate() === new Date().getDate()) {
                tdTotal += l.useMoney || 0
              }
            }
          }

          if (newU + tdTotal > parseInt(this.levs[this.user.lev].daylim)) {
            this.number2 = (parseInt(this.levs[this.user.lev].daylim) - tdTotal) / this.cgp
            if (!this.warn) {
              window.alert(sify('您目前等級的每日交易上限為：') + this.levs[this.user.lev].daylim)
              this.warn = true
            }
          }
          this.useMoney = newU
        }
      }
      if (this.action === 'sell') {
        if (newNum >= 0 && this.m === '白銀') {
          if (newNum > this.user[this.acc].silver) {
            this.number = this.user[this.acc].silver
          }
          this.useMoney = Math.floor((this.number2 * (this.csp - this.config.deltaSellSilver)) * 100) / 100
        }
        if (newNum >= 0 && this.m === '黃金') {
          if (newNum > this.user[this.acc].gold) {
            this.number = this.user[this.acc].gold
          }
          this.useMoney = Math.floor((this.number2 * (this.cgp - this.config.deltaSellGold)) * 100) / 100
        }
      }
    }
  },
  mounted () {
    const vm = this
    vm.silverPrice = [19.26, 19.35, 19.01, 19.26, 19.64, 19.13, 19.70, 18.90, 19.27, 18.99, 19.70, 18.47, 19.41, 18.43, 19.86, 18.93, 18.94, 19.72, 18.63, 19.66, 18.55, 19.35, 18.11]

    vm.silverDots = vm.silverPrice.map((n, idx) => {
      return {
        id: idx + 400,
        cx: 500 - idx * 150 / 10 / 3 * 5,
        cy: 305 - (parseFloat(n) - 17) * 50
      }
    })

    vm.silverRects = vm.silverPrice.map((n, idx) => {
      return {
        id: idx + 1000,
        x: 500 - idx * 150 / 10 / 3 * 5 - 10,
        y: 280,
        c: n > vm.silverPrice[idx + 1] ? '#b33' : '#3b3',
        h: Math.abs(n - (vm.silverPrice[idx + 1] || n)) * 50
      }
    })

    vm.spts = vm.silverDots.map((o) => {
      return o.cx + ',' + o.cy
    }).join(' ') + ' 0,300 500,300'

    vm.goldPrice = [1666.10, 1671.60, 1661.10, 1666.10, 1677.90, 1662.10, 1676.90, 1661.40, 1666.30, 1661.10, 1698.00, 1630.20, 1702.90, 1623.40, 1725.40, 1645.40, 1718.10, 1672.20, 1709.90, 1674.70, 1718.90, 1665.90, 1703.20]

    vm.goldDots = vm.goldPrice.map((n, idx) => {
      return {
        id: idx + 800,
        cx: 500 - idx * 150 / 10 / 3 * 5,
        cy: 310 - (parseFloat(n) - 1500)
      }
    })

    vm.goldRects = vm.goldPrice.map((n, idx) => {
      return {
        id: idx + 1000,
        x: 500 - idx * 150 / 10 / 3 * 5 - 10,
        y: 280,
        c: n > vm.goldPrice[idx + 1] ? '#b33' : '#3b3',
        h: Math.abs(n - (vm.goldPrice[idx + 1] || n))
      }
    })

    vm.gpts = vm.goldDots.map((o) => {
      return o.cx + ',' + o.cy
    }).join(' ') + ' 0,300 500,300'

    this.updateP()
    setInterval(this.updateP, 20 * 1000) // 60*60
  },
  created () {
    window.addEventListener('resize', this.isW)
  },
  destroyed () {
    window.removeEventListener('resize', this.isW)
  }
}
</script>

<style type="text/css" scoped>
  .home {
    background-color: #111;
    color: white;
  }
  .ui.form {
    background-color: #222;
  }
  .ui.segment {
    background-color: #222;
  }
  .ui.form .field {
    padding: 0 3em;
  }
  .ui.form .field .label {
    background-color: #111;
    color: white;
  }
  .ui.form .field .input input {
    background-color: #111;
    color: white;
  }
  #p, .divided.buttons .button {
    background-color: #111;
    color: white;
  }
  #p:hover, #p:active, #p.active {
    border-color: #c33 !important;
    background-color: #911 !important;
  }
  .sell #p:hover, .sell #p:active, .sell #p.active {
    border-color: green !important;
    background-color: green !important;
  }

  .buy.button {
    border-color: red !important;
  }

  .buy.button:hover, .bug.button:active, .buy.button.active {
    background-color: red !important;
  }

  .sell.button {
    border-color: green !important;
  }

  .sell.button:hover, .sell.button:active, .sell.button.active {
    background-color: green !important;
  }

  .red.list {
    border: 2px outset red;
    border-radius: 30px;
    width: 88%;
    height: 300px;
    margin: 0 auto;
  }

  .chart {
    background-color: #222;
 -webkit-transform-origin: left;
    -moz-transform-origin: left;
     -ms-transform-origin: left;
      -o-transform-origin: left;
    transform-origin: left;
  }

  #w {
    position: relative;
    z-index: 999;
    color: #ccc !important;
    border: 1px solid #999 !important;
  }

  #w:hover, #w:active, #w.active {
    background-color: #999 !important;
  }

  #rb, #rm {
    width: 46% !important;
  }

  #rm {
    padding-top: .6em;
    text-align: left;
  }

  #rm .item {
    text-align: left;
  }

  #wra {
    height: 350px;
    overflow: hidden;
  }

  #mm {
    display: flex;
    flex-direction: column;
    overflow: visible;
 -webkit-transform-origin: top;
    -moz-transform-origin: top;
     -ms-transform-origin: top;
      -o-transform-origin: top;
      transform-origin: top;
 -webkit-transform: scaleY(0.8);
    -moz-transform: scaleY(0.8);
     -ms-transform: scaleY(0.8);
      -o-transform: scaleY(0.8);
      transform: scaleY(0.8);
  }

  @media screen and (max-width: 400px) {
    .chart {
 -webkit-transform: scaleX(0.68);
    -moz-transform: scaleX(0.68);
     -ms-transform: scaleX(0.68);
      -o-transform: scaleX(0.68);
      transform: scaleX(0.68);
    }
  }

  @media screen and (min-width:  401px) and (max-width: 601px) {
    .chart {
 -webkit-transform: scaleX(0.78);
    -moz-transform: scaleX(0.78);
     -ms-transform: scaleX(0.78);
      -o-transform: scaleX(0.78);
      transform: scaleX(0.78);
    }
  }

  @media screen and (min-width: 701px) and (max-width: 930px) {
    .chart {
 -webkit-transform: scaleX(0.92);
    -moz-transform: scaleX(0.92);
     -ms-transform: scaleX(0.92);
      -o-transform: scaleX(0.92);
      transform: scaleX(0.92);
      position: relative;
      left: -25px;
    }
  }

  @media screen and (min-width: 931px) and (max-width: 1030px) {
    .chart {
 -webkit-transform: scaleX(1.2);
    -moz-transform: scaleX(1.2);
     -ms-transform: scaleX(1.2);
      -o-transform: scaleX(1.2);
      transform: scaleX(1.2);
    }
  }

  @media screen and (min-width: 1031px) and (max-width: 1500px) {
    .chart {
 -webkit-transform: scaleX(1.4);
    -moz-transform: scaleX(1.4);
     -ms-transform: scaleX(1.4);
      -o-transform: scaleX(1.4);
      transform: scaleX(1.4);
    }
  }

  @media screen and (min-width: 1501px) {
    .chart {
 -webkit-transform: scaleX(2.2);
    -moz-transform: scaleX(2.2);
     -ms-transform: scaleX(2.2);
      -o-transform: scaleX(2.2);
      transform: scaleX(2.2);
    }
  }

  .l {
    text-align: left;
  }

  #pTable tr th, #pTable tr td{
    background-color: #333;
  }

  #pTable tr th {
    color: #666;
  }

  .price {
    width: 100%;
    height: 300px;
  }

  #bag {
    width: 100%;
    height: 180px;
    overflow: hidden;
  }

  #pre {
    /* border-top: 2px solid white; */
  }

  #pre .ui.button {
    border: 1px solid #333;
    margin: 0 .6em;
    font-size: 12px;
  }

  #pre.sell .ui.button.active {
    border: 1px solid green;
    background-color: green !important;
  }

  @media screen and (max-width: 770px) {
    #mm {
      margin: 0 auto !important;
    }
  }

  @media screen and (max-width: 600px) {
    #mm {
      width: 100% !important;
    }
    .price {
      width: 100%;
      height: 300px;
    }

    #bag {
      width: 100%;
      height: 180px;
      overflow: hidden;
    }

    #pre .ui.button {
      border: 1px solid #333;
      margin: 0;
      border-radius: 0;
    }
  }

  .left.aligned.item {
    text-align: left;
    margin-left: .6em;
  }

  .ui.grid {
    width: 100% !important;
    text-align: center;
    overflow: visible !important;
  }

  .my_column {
    display: block;
    margin: 1em 1em;
    overflow: visible;
  }

  #ff {
    position: absolute;
    right: 0;
  }

  svg text {
    font-size: 9px;
  }

  .left.aligned.field {
    text-align: left;
    border: 3px solid #666;
    height: 290px !important;
    width: 36% !important;
  }

  .ui.form.fat-only {
    height: 290px !important;
    overflow: hidden !important;
  }

  .fluid.grid {
    margin-top: 2em;
    width: 100%;
  }

  .ui.form.fat-only .ff .ui.button {
    margin-top: .6em;
    margin-bottom: .6em;
    padding: .4em;
    width: 100%;
  }

  .dotted {
    border: 2px dotted #999;
    border-radius: 5px;
  }

  .dotted.buy {
    color: #a33;
  }

  .dotted.sell{
    color: #3a3;
  }

  .form .big.field {
    width: 64% !important;
  }

  .form .field .big,  .ui.form {
    border: 3px solid #666;
  }

  .big.field .item {
    margin: 1em .6em;
  }

  .down {
    position: relative;
    top: .8em;
    color: #999;
  }

  .ff {
    width: 48%;
    border-top: 3px solid #666;
    border-left: 1px solid #666;
    border-right: 1px solid #666;
    height: 420px !important;
  }

  .ff input {
    text-align: center;
    border-radius: 0 !important;
    border: 1px solid #666;
  }

  .ff .label,  .ff input {
    background-color: #222 !important;
    border: 2px solid #aaa !important;
  }

  .ui.label.plus {
    border-right: none !important;
  }

  .ui.label.minus {
    border-radius: 0;
    border-left: none !important;
    border-top-right-radius: .28571429rem;
    border-bottom-right-radius: .28571429rem;
  }

  .divided.buttons .button {
    margin-top: 1.6em !important;
  }

  .divided.buttons .button {
    margin: 0 .3em;
    width: 50px !important;
    border-width: 1px;
    border-style: solid;
  }
</style>
