<template>
  <div class="home">
    <div class="ui segment container inverted">
      <h2>{{sify('以邀請碼註冊')}}</h2>
      <div>
        <form class="ui form" v-show="a == 'Register'">
          <div>
            <h4>{{sify('邀請人ID:')}}{{perentID}}</h4>
          </div>
          <div class="ui left icon input">
            <input
              type="text"
              :placeholder="sify('帳號')"
              v-model="email"
            />
            <i class="user icon"></i>
          </div>
          <br/>
          <div class="ui left icon input">
            <input
              type="password"
              :placeholder="sify('密碼')"
              v-model="password"
            />
            <i class="lock icon"></i>
          </div>
          <br/>
          <div class="ui visible warning message" v-show="!vPass() && showW2">
            <a id="ra" @click = "showW2 = false"><i class="close icon"></i></a>
            <div class="header">
              {{sify('Password太短')}}
            </div>
              {{sify('Password至少需要6個字元')}}
          </div>
          <br/>
          <a class="ui huge green button" @click="register()">{{sify('註冊')}}</a>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { sify } from 'chinese-conv'
// import * as firebase from 'firebase/app'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'

const auth = getAuth()

export default {
  name: 'LoGin',
  metaInfo: {
    title: sify('登入或註冊')
  },
  data () {
    return {
      showW1: true,
      showW2: true,
      email: '',
      realEmail: null,
      password: '',
      a: 'Register',
      remember: false,
      perentID: null
    }
  },
  props: ['uid'],
  watch: {
    remember (newR, oldR) {
      // console.log(oldR)
      localStorage.remember = newR
      if (!newR) {
        localStorage.email = ''
        localStorage.password = ''
        localStorage.realEmail = ''
      }
    },
    email (newE) {
      if (newE !== '') {
        this.realEmail = this.addPrefix(newE)
      }
    }
  },
  mounted () {
    this.perentID = this.$route.params.id
  },
  methods: {
    vPass () {
      return (!this.password || this.password.split('').length > 5)
    },
    addPrefix (s) {
      if (s.indexOf('@') === -1) {
        console.log('addPrefix')
        return s + '@trader.com'
      } else {
        return s
      }
    },
    reload () {
      window.location.reload(true)
    },
    sify (t) {
      if (this.si) {
        return sify(t)
      } else {
        return t
      }
    },
    createAccount (uid, email) {
      // console.log('a')
      const vm = this
      vm.$emit('createAccount', uid, vm.realEmail, vm.perentID)
    },
    register () {
      const vm = this
      createUserWithEmailAndPassword(auth, vm.realEmail, this.password).then((data) => {
        // console.log(data.user)
        vm.createAccount(data.user.uid, vm.realEmail)
        alert(sify('您已成功註冊! 請登入'))
        this.$router.push('/')
      })
        .catch(error => {
          console.log(error.message)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.home {
  width: 100vw;
  height: 100vh;
  background-image: url('https://i.imgur.com/A6zcHWa.jpg');
  background-size: cover;
  background-color: #666 ;
}

.ui.segment.container.inverted {
  position: relative;
  top: 25vh;
  background-color: #333;
  width: 40vw;
  min-width: 300px;
  height: 60vh;
  min-height: 400px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

input {
  background-color: #111 !important;
  font-size: 22px !important;
  color: white !important;
}

.ui.input {
  margin: .6em;
}

i.icon {
  color: #666 !important;
}

.gray.link {
  cursor: pointer;
  margin-left: 2em;
  text-decoration: underline;
  color: #42b983;
  font-size: 16px;
}

.pink.link {
  cursor: pointer;
  margin-left: 2em;
  text-decoration: underline;
  color: #933;
  font-size: 16px;
}

#r {
  text-align: left;
  width: 80%;
}

#r label {
  color: white;
}
.ui.visible.warning.message {
  background-color: #111;
  position: relative;
}

#ra {
  position: absolute;
  right: 2em;
  top: .5em;
}

</style>
