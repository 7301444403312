<template>
  <div class="hello">
    <div class="ui segment container inverted">
      <h2>Login Trader</h2>
      <div class="ui buttons" v-show = "!a">
        <a class="ui huge blue button" @click="a = 'Register'">{{sify('註冊')}}</a>
        <div class="or"></div>
        <a class="ui huge green button" @click="a = 'Login'">{{sify('登入')}}</a>
      </div>
      <div>
        <form class="ui form" v-show="a == 'Register'">
          <div class="ui left icon input">
            <input
              type="text"
              :placeholder="sify('帳號')"
              v-model="email"
            />
            <i class="user icon"></i>
          </div>
          <div class="ui visible error message" v-show="!vEmail() && showW1">
            <br/>
            <a id="ra" @click = "showW1 = false"><i class="close icon"></i></a>
            <div class="header">
              {{sify('帳號已被使用，請換一個')}}
            </div>
              {{sify('不能註冊已存在的帳號')}}
          </div>
          <br/>
          <div class="ui left icon input">
            <input
              type="password"
              :placeholder="sify('密碼')"
              v-model="password"
            />
            <i class="lock icon"></i>
          </div>
          <div class="ui visible warning message" v-show="!vPass() && showW2">
            <br/>
            <a id="ra" @click = "showW2 = false"><i class="close icon"></i></a>
            <div class="header">
              {{sify('Password太短')}}
            </div>
              {{sify('Password至少需要6個字元')}}
          </div>
          <br/>
          <a class="ui huge green button" @click="register()" :class="{disabled: !vEmail()}">{{sify('註冊')}}</a>
        </form>
        <form class="ui form" v-show="a == 'Login'">
          <div class="ui left icon input">
            <input
              type="text"
              :placeholder="sify('帳號')"
              v-model="email"
            />
            <i class="user icon"></i>
          </div>
          <br/>
          <div class="ui left icon input">
            <input
              type="password"
              :placeholder="sify('密碼')"
              v-model="password"
            />
            <i class="lock icon"></i>
          </div>
          <br/>
          <div id = "r" class="ui checkbox">
          <input type="checkbox" name="example" v-model="remember">
            <label>{{sify('記住我')}}</label>
          </div>
          <br/>
          <br/>
          <a class="ui huge blue button" @click="tryLogin()">
             {{sify('登入')}}
          </a>
          <a class="small gray link" @click="a = 'Register'">{{sify('新用戶? 按此註冊')}}</a>
          <br/>
          <br/>
          <br/>
          <br/>
          <a class="small pink link" @click="reload()">{{sify('無法正常顯示? 按此清快取')}}</a>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { sify } from 'chinese-conv'
// import * as firebase from 'firebase/app'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'

const auth = getAuth()

export default {
  name: 'LoGin',
  metaInfo: {
    title: sify('登入或註冊')
  },
  data () {
    return {
      showW1: true,
      showW2: true,
      email: '',
      realEmail: null,
      password: '',
      a: 'Login',
      remember: false
    }
  },
  props: ['uid', 'users'],
  watch: {
    remember (newR, oldR) {
      // console.log(oldR)
      localStorage.remember = newR
      if (!newR) {
        localStorage.email = ''
        localStorage.password = ''
        localStorage.realEmail = ''
      }
    },
    email (newE) {
      if (newE !== '') {
        this.realEmail = this.addPrefix(newE)
      }
    }
  },
  mounted () {
    if (localStorage.remember) {
      this.email = localStorage.email
      this.realEmail = localStorage.realEmail
      this.password = localStorage.password
      this.remember = localStorage.remember
    }
  },
  methods: {
    addPrefix (s) {
      if (s.indexOf('@') === -1) {
        // console.log('addPrefix')
        return s + '@trader.com'
      } else {
        return s
      }
    },
    vEmail () {
      // console.log(this.users)
      var ans = true
      const ks = Object.keys(this.users)
      if (this.users) {
        for (var i = 0; i < ks.length; i++) {
          const e = this.users[ks[i]].email
          // console.log(e)
          if (this.email === e || this.realEmail === e) {
            ans = false
          }
        }
      }
      return ans
    },
    vPass () {
      return (!this.password || this.password.split('').length > 5)
    },
    reload () {
      window.location.reload(true)
    },
    sify (t) {
      if (this.si) {
        return sify(t)
      } else {
        return t
      }
    },
    createAccount (uid, email) {
      // console.log('a')
      const vm = this
      vm.$emit('createAccount', uid, vm.realEmail)
    },
    register () {
      const vm = this
      createUserWithEmailAndPassword(auth, vm.realEmail, this.password).then((data) => {
        // console.log(data.user)
        vm.createAccount(data.user.uid, vm.realEmail)
        alert(sify('您已成功註冊! 請登入'))
        vm.a = 'Login'
        // this.$router.push('/')
      })
        .catch(error => {
          console.log(error.message)
        })
    },
    tryLogin () {
      console.log('try to login as:' + (this.realEmail || this.email))
      const vm = this
      signInWithEmailAndPassword(auth, this.realEmail || this.email, this.password)
        .then((data) => {
          console.log('您已成功登入')
          alert(sify('您已成功登入'))
          console.log(data.user)
          vm.user = data.user
          vm.$emit('logged', data.user.uid, this.addPrefix(data.user.email))
        })
        .catch(error => {
          console.log(error.message)
        })

      if (this.remember) {
        localStorage.email = this.email
        localStorage.realEmail = this.realEmail
        localStorage.password = this.password
      }
      // console.log('a')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.hello {
  width: 100vw;
  height: 100vh;
  background-image: url('https://i.imgur.com/A6zcHWa.jpg');
  background-size: cover;
  background-color: #66f ;
}

.ui.segment.container.inverted {
  position: relative;
  top: 25vh;
  background-color: #333;
  width: 40vw;
  min-width: 300px;
  height: 60vh;
  min-height: 400px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

input {
  background-color: #111 !important;
  font-size: 22px !important;
  color: white !important;
}

.ui.input {
  margin: .6em;
}

i.icon {
  color: #666 !important;
}

.gray.link {
  cursor: pointer;
  margin-left: 2em;
  text-decoration: underline;
  color: #42b983;
  font-size: 16px;
}

.pink.link {
  cursor: pointer;
  margin-left: 2em;
  text-decoration: underline;
  color: #933;
  font-size: 16px;
}

#r {
  text-align: left;
  width: 80%;
}

#r label {
  color: white;
}

.ui.visible.warning.message, .ui.visible.error.message {
  background-color: #999;
  position: relative;
}

#ra {
  position: absolute;
  right: 2em;
  top: .5em;
}

</style>
