export default {
  name: 'countAll',
  methods: {
    countTax (id) {
      var ans = 0
      const ls = this.users[id][this.acc].logs
      if (!ls) {
        return 0
      }
      for (var i = 0; i < ls.length; i++) {
        if (ls[i].action === 'tax') {
          ans += parseFloat(ls[i].benifit)
        }
      }
      return ans
    },
    countAll () {
      return this.countAllById(this.uid, this.acc)
    },
    countAllById (id, acc, todayOnly) {
      var ans = 0
      var moneyDelta = 0
      const ls = (this.users[id][acc].logs || [])
      for (let i = 0; i < ls.length; i++) {
        const l = ls[i]
        const today = new Date()
        if (todayOnly && today.toDateString() !== new Date(l.time).toDateString()) {
          continue
        }
        // console.log(moneyDelta)
        if (l.action === 'buy' && !l.done) {
          if (l.m === '黃金') {
            const useMoney = l.number * (l.price + l.delta)
            const goldDeltaUp = l.number * (1 - l.fee)
            moneyDelta += goldDeltaUp * (this.cgp - this.config.deltaSellGold) - useMoney
          }
          if (l.m === '白銀') {
            const useMoney = l.number * (l.price + l.delta)
            const silverDeltaUp = l.number * (1 - l.fee)
            moneyDelta += silverDeltaUp * (this.csp - this.config.deltaSellSilver) - useMoney
          }
        }
        if (l.action === 'buynull' && !l.done) {
          if (l.m === '黃金') {
            const useMoney = l.number * (l.price + l.delta) - l.number * (l.price + l.delta) * l.fee
            const goldDeltaDown = l.number - l.number * l.fee
            moneyDelta += useMoney * (1 - l.fee) - goldDeltaDown * (this.cgp + this.config.deltaSellGold)
          }
          if (l.m === '白銀') {
            const useMoney = l.number * (l.price + l.delta) - l.number * (l.price + l.delta) * l.fee
            const silverDeltaDown = l.number - l.number * l.fee
            moneyDelta += useMoney * (1 - l.fee) - silverDeltaDown * (this.csp + this.config.deltaSellSilver)
          }
        }
      }
      ans = 0 + moneyDelta
      return ans
    },
    countMeanPrice (uid, action, price, delta, number, fee, m, total) {
      var p = 0
      var n = 0
      const ls = (this.users[uid][this.acc].logs || []).filter(function (o) {
        return o.m === m
      })
      for (let i = 0; i < ls.length; i++) {
        const l = ls[i]
        if (l.action === 'buy' && l.price && l.delta) {
          p += (l.price + l.delta) * l.number
          n += (l.number - (l.number * l.fee))
        }
      }
      if (action === 'buy' && !total) {
        p += (price + delta) * number
        n += (number - (number * fee))
      }
      // console.log(n)
      if (n === 0) {
        if (m === '黃金') { return this.cgp }
        if (m === '白銀') { return this.csp }
      }
      // console.log(p / n)
      return p / n
    },
    countTodayFee () {
      var ans = 0
      const ls = (this.user[this.acc].logs || [])
      for (let i = 0; i < ls.length; i++) {
        const l = this.user[this.acc].logs[i]
        if (new Date(l.time).getDate() === new Date().getDate()) {
          if (l.action === 'buy') {
            ans += parseFloat(l.fee)
          }
          if (l.action === 'buynull') {
            ans += parseFloat(l.fee)
          }
        }
      }
      return ans
    },
    countToday () {
      var ans = this.countAllById(this.uid, this.acc, true)
      const ls = (this.user[this.acc].logs || []).filter(function (o) {
        const today = new Date()
        if (o.done) { return true }
        if (today.toDateString() !== new Date(o.time).toDateString()) {
          return false
        } else {
          if (!o.done) { return false }
          return true
        }
      })
      var la
      var lb
      if (ls.length >= 2 && ls[0].moneyBefore > 0 && ls[ls.length - 1].moneyAfter > 0) {
        lb = ls[0]
        la = ls[ls.length - 1]
        ans += ((la.moneyAfter || 0) - (lb.moneyBefore || 0))
      }
      return ans
    }
    /*
    countToday () {
      var ans = 0
      var goldDeltaUp = 0
      var goldDeltaDown = 0
      var silverDeltaUp = 0
      var silverDeltaDown = 0
      var moneyDelta = 0
      const ls = (this.user[this.acc].logs || [])
      for (let i = 0; i < ls.length; i++) {
        const l = this.user[this.acc].logs[i]
        if (new Date(l.time).getDate() === new Date().getDate()) {
          if (l.action === 'buy') {
            if (l.m === '黃金') {
              const useMoney = l.number * (l.price + l.delta)
              goldDeltaUp += l.number * (1 - l.fee)
              moneyDelta -= useMoney
            }
            if (l.m === '白銀') {
              const useMoney = l.number * (l.price + l.delta)
              silverDeltaUp += l.number * (1 - l.fee)
              moneyDelta -= useMoney
            }
          }
          if (l.action === 'buynull') {
            if (l.m === '黃金') {
              const useMoney = l.number * (l.price + l.delta)
              goldDeltaDown += l.number * (1 - l.fee)
              moneyDelta += useMoney - 2 * useMoney * l.fee
            }
            if (l.m === '白銀') {
              const useMoney = l.number * (l.price + l.delta)
              silverDeltaDown += l.number * (1 - l.fee)
              moneyDelta += useMoney - 2 * useMoney * l.fee
            }
          }
          if (l.action === 'sell') {
            if (l.m === '黃金') {
              const useMoney = l.number * (l.price - l.delta)
              goldDeltaUp -= l.number
              moneyDelta += useMoney * (1 - l.fee)
            }
            if (l.m === '白銀') {
              silverDeltaUp -= l.number
              const useMoney = l.number * (l.price - l.delta)
              moneyDelta += useMoney * (1 - l.fee)
            }
          }
          if (l.action === 'sellnull') {
            if (l.m === '黃金') {
              goldDeltaDown -= l.number
            }
            if (l.m === '白銀') {
              silverDeltaDown -= l.number
            }
            const money = 2 * l.number * parseFloat(l.oldPrice) - 2 * l.number * l.fee * parseFloat(l.oldPrice) - ((l.price + l.delta) * l.number - (l.price + l.delta) * l.number * l.fee)
            moneyDelta += money
          }
        }
      }
      ans += moneyDelta
      ans += goldDeltaUp * (this.cgp - this.config.deltaSellGold)
      ans -= goldDeltaDown * (this.cgp + this.config.deltaSellGold)
      ans += silverDeltaUp * (this.csp - this.config.deltaSellSilver)
      ans -= silverDeltaDown * (this.csp + this.config.deltaSellSilver)
      return ans
    } */
  }
}
