<template>
  <div id="app">
    <!-- <nav class="ui inverted menu" v-show="uid && user &&  user.role == 'admin'">
      <router-link class="item" to="/admin">
        <sui-icon name="home" />
      </router-link>
    </nav> -->
    <nav class="ui inverted menu" v-show="uid && (!user || !user.role || user.role !== 'admin')">
      <div class="item">
        <sui-dropdown :text="sify(m)">
          <sui-dropdown-menu>
            <sui-dropdown-item>
              <a @click="m = '黃金'">{{sify('黃金')}}</a>
            </sui-dropdown-item>
            <sui-dropdown-item>
              <a @click="m = '白銀'">{{sify('白銀')}}</a>
            </sui-dropdown-item>
          </sui-dropdown-menu>
        </sui-dropdown>
      </div>

      <div class="item ver" v-show = "m == '黃金'">
        <div class="up">{{sify('最新價')}}</div>
        <div class="down">{{pretty(cgp)}}</div>
      </div>
      <div class="item ver" v-show = "m == '白銀'">
        <div class="up">{{sify('最新價')}}</div>
        <div class="down">{{pretty(csp)}}</div>
      </div>

      <div class="item ver">
        <div class="up">{{sify('更新時間')}}</div>
        <div class="down white">{{par(fetchTime) }}</div>
      </div>

      <div class="item">
        <sui-dropdown :text="mode == 'days' ? sify('近日') : sify('即時')">
          <sui-dropdown-menu>
            <sui-dropdown-item>
              <a @click="mode = 'days'">
                <i class="calendar icon" />{{sify('近日')}}</a>
            </sui-dropdown-item>
            <sui-dropdown-item>
              <a @click="mode = 'realtime'">
                <i class="clock icon" />{{sify('即時')}}</a>
            </sui-dropdown-item>
          </sui-dropdown-menu>
        </sui-dropdown>
      </div>

      <div class="right menu">
        <a class="item" @click="visible = !visible">
          <i class="ellipsis horizontal icon" />
<!--          <sui-dropdown icon="ellipsis horizontal icon">
            <sui-dropdown-menu>
              <sui-dropdown-item>{{email}}您好
              </sui-dropdown-item>
              <sui-dropdown-item>
                <a @click="logout()">{{sify('登出')}}</a>
              </sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown> -->
        </a>
      </div>
    </nav>
    <sui-sidebar-pushable>
      <sui-menu
        is="sui-sidebar"
        :visible="visible"
        animation="overlay"
        width="thin"
        icon="labeled"
        inverted
        direction="right"
        vertical
      >
        <!-- <sui-menu-item>
          <router-link to="/">
            <sui-icon name="home" />
          </router-link>
        </sui-menu-item> -->
        <sui-menu-item>
          <router-link to="/">
            <i class="balance scale icon" />
            交易頁面
          </router-link>
        </sui-menu-item>
        <!-- <sui-menu-item>
          <router-link to="/about">
            關於本站
          </router-link>
        </sui-menu-item> -->
        <sui-menu-item>
          <router-link to="/account">
            <i class="user icon" />
            會員資料
          </router-link>
        </sui-menu-item>
        <sui-menu-item>
          <router-link to="/service">
            <i class="comment icon" />
            客戶服務
          </router-link>
        </sui-menu-item>
        <sui-menu-item>
          <a @click="logout()">
            <i class="power off icon" />
            {{sify('登出')}}
          </a>
        </sui-menu-item>
      </sui-menu>
      <sui-sidebar-pusher>
        <router-view :acc = "acc" :m = "m" :uid="uid" :users="users" :cgp="cgp" :csp="csp" :mode="mode" :user="users[uid]" @logged="logged" @createAccount="createAccount" @buy="buy" @buynull="buynull" @sell="sell" @sellnull="sellnull" :config="config" :levs="levs" :newLog="newLog" @updateAcc="updateAcc" @updateCGP="updateCGP" @updateCSP="updateCSP" @updateTime="updateTime" @updateConfig="updateConfig" @updateUser="updateUser" @toggleUser="toggleUser" @levelUser="levelUser" @updateLev="updateLev" @toggleLev="toggleLev" @logout="logout" :reports= "reports" @send="send" @flat="flat"/>
      </sui-sidebar-pusher>
    </sui-sidebar-pushable>
  </div>
</template>

<script type="text/javascript">
import { sify } from 'chinese-conv'
// import * as firebase from 'firebase/app'
import { getAuth, signOut } from 'firebase/auth'
import { ref, set, onValue } from 'firebase/database'
import { db } from './db.js'

const auth = getAuth()

export default {
  name: 'App',
  metaInfo: {
    title: '歡迎',
    titleTemplate: '%s | ' + sify('gzdztrader')
  },
  data () {
    return {
      fetchTime: new Date().getTime(),
      reports: [],
      mode: 'days',
      cgp: 11705.90,
      csp: 138.85,
      acc: 'demo',
      newLog: null,
      config: { delta: 0, fee: 0 },
      levs: [],
      visible: false,
      uid: null,
      // uid: 'test', // null
      email: null,
      m: '黃金',
      users: [],
      user: null
    }
  },
  methods: {
    flat (log, acc, uid) {
      if (log.done) { return }
      const id = log.id
      const logs = this.user[acc || this.acc].logs.map(function (l) {
        if (l.id === id) {
          l.done = true
        }
        return l
      })
      set(ref(db, 'users/' + (uid || this.uid) + '/' + (acc || this.acc) + '/logs'), logs).then(() => {
        console.log('logs updated!')
      })
      var useMoney
      var price
      if (log.action === 'buy') {
        if (this.m === '黃金') {
          useMoney = (this.cgp - this.config.deltaSellGold) * (log.number - (this.config.fee * log.number))
          price = this.cgp
        }
        if (this.m === '白銀') {
          useMoney = (this.csp - this.config.deltaSellSilver) * (log.number - (this.config.fee * log.number))
          price = this.csp
        }
        this.sell((log.number - (this.config.fee * log.number)), useMoney, price, (acc || this.acc), (uid || this.uid))
      }
      if (log.action === 'buynull') {
        if (this.m === '黃金') {
          useMoney = (this.cgp + this.config.deltaSellGold) * log.number
          price = this.cgp
        }
        if (this.m === '白銀') {
          useMoney = (this.csp + this.config.deltaSellSilver) * log.number
          price = this.csp
        }
        this.sellnull((log.number - (this.config.fee * log.number)), useMoney, price, log.price, (acc || this.acc), (uid || this.uid))
      }
    },
    send (id, detail) {
      this.reports = this.reports || []
      this.reports.push({
        time: new Date().getTime(),
        id: id,
        detail: detail,
        replied: false
      })
      set(ref(db, 'reports'), this.reports).then(() => {
        console.log('reports updated!')
      })
    },
    pretty (n) {
      if (('' + n).split('.')[1]) {
        return ('' + n).split('.')[0] + '.' + ('' + n).split('.')[1].substr(0, 2)
      } else {
        return ('' + n)
      }
    },
    par (t) {
      return (new Date(t).getFullYear() + '-' + (new Date(t).getMonth() + 1) + '-' + new Date(t).getDate() + ':' + new Date(t).getHours() + ':' + new Date(t).getMinutes() + ':' + new Date(t).getSeconds())
    },
    updateTime (t) {
      this.fetchTime = t
    },
    updateCGP (n) {
      this.cgp = n
    },
    updateCSP (n) {
      this.csp = n
    },
    sify (t) {
      return sify(t)
    },
    updateAcc (acc) {
      this.acc = acc
    },
    logged (uid, email) {
      console.log(sify('Trader交易學習網是一個摹擬交易黃金和白銀期貨的虛擬交易所。其中價格來自真實的市價，但所有交易不涉及真正的金流，均為摹擬。'))
      this.uid = uid
      this.email = email
      this.user = this.users[this.uid]
      if (this.user.role === 'admin') {
        this.$router.push('/admin')
      }
    },
    logout () {
      this.uid = null
      this.email = null
      this.user = null
      this.visible = false
      signOut(auth).then(() => {
        alert(sify('您已成功登出'))
        this.$router.push('/')
        // Sign-out successful.
      }).catch((error) => {
        console.log(error.message)
      })
    },
    createAccount (uid, email, pid) {
      // console.log('c')
      set(ref(db, 'users/' + uid),
        {
          id: uid,
          email: email,
          level: 1,
          pid: (pid || 'nobody'),
          demo: {
            gold: 0,
            silver: 0,
            money: this.config.version === 'beta' ? this.config.startDemoMoney : 0
          },
          formal: {
            gold: 0,
            silver: 0,
            money: this.config.version === 'beta' ? this.config.startFormalMoney : 0
          },
          createTime: new Date().getTime()
        }).then(() => {
        console.log('user created!')
      })
      if (pid && pid !== 'nobody') {
        this.setChildren(pid, uid)
      }
    },
    countMeanPrice (uid, action, price, delta, number, fee, m, total) {
      var p = 0
      var n = 0
      const ls = (this.users[uid][this.acc].logs || []).filter(function (o) {
        return o.m === m
      })
      for (let i = 0; i < ls.length; i++) {
        const l = ls[i]
        if (l.action === 'buy' && l.price && l.delta) {
          p += (l.price + l.delta) * l.number
          n += (l.number - (l.number * l.fee))
        }
      }
      if (action === 'buy' && !total) {
        p += (price + delta) * number
        n += (number - (number * fee))
      }
      if (n === 0) {
        if (m === '黃金') { return this.cgp }
        if (m === '白銀') { return this.csp }
      }
      return p / n
    },
    buy (number, useMoney, price) {
      if (this.m === '黃金') {
        this.users[this.uid][this.acc].gold = parseFloat(this.users[this.uid][this.acc].gold) + parseFloat(number) - (this.config.fee * parseFloat(number))
      }
      if (this.m === '白銀') {
        this.users[this.uid][this.acc].silver = parseFloat(this.users[this.uid][this.acc].silver) + parseFloat(number) - (this.config.fee * parseFloat(number))
      }
      const mb = 0 + this.users[this.uid][this.acc].money
      this.users[this.uid][this.acc].money -= useMoney

      let delta = this.config.delta
      if (this.m === '黃金') {
        delta = this.config.deltaBuyGold
      }
      if (this.m === '白銀') {
        delta = this.config.deltaBuySilver
      }

      const obj = {
        time: new Date().getTime(),
        moneyBefore: mb,
        moneyAfter: this.users[this.uid][this.acc].money,
        action: 'buy',
        status: 'sucuess',
        price: price,
        useMoney: useMoney,
        // benifit: Math.round((this.countMeanPrice(this.uid, 'buy', price, delta, number, this.config.fee, this.m) - (price + delta) * number / (number - (number * this.config.fee))) * 100) / 100,
        mp: this.countMeanPrice(this.uid, 'buy', price, delta, number, this.config.fee, this.m),
        m: this.m,
        number: number,
        delta: delta,
        fee: this.config.fee,
        id: Math.floor(Math.random() * 1000000)
      }
      if (!this.users[this.uid][this.acc].logs) {
        this.users[this.uid][this.acc].logs = []
      }
      this.users[this.uid][this.acc].logs.push(obj)
      this.newLog = { ...obj }
      this.updateU()
    },
    buynull (number, useMoney, price) {
      console.log(number)
      if (this.m === '黃金') {
        this.users[this.uid][this.acc].gold = parseFloat(this.users[this.uid][this.acc].gold) + parseFloat(number) - (this.config.fee * parseFloat(number))
      }
      if (this.m === '白銀') {
        this.users[this.uid][this.acc].silver = parseFloat(this.users[this.uid][this.acc].silver) + parseFloat(number) - (this.config.fee * parseFloat(number))
      }
      const mb = 0 + this.users[this.uid][this.acc].money

      this.users[this.uid][this.acc].money -= useMoney

      let delta = this.config.delta
      if (this.m === '黃金') {
        delta = this.config.deltaBuyGold
      }
      if (this.m === '白銀') {
        delta = this.config.deltaBuySilver
      }

      const obj = {
        time: new Date().getTime(),
        action: 'buynull',
        status: 'sucuess',
        price: price,
        moneyBefore: mb,
        moneyAfter: this.users[this.uid][this.acc].money,
        useMoney: useMoney,
        // benifit: Math.round((this.countMeanPrice(this.uid, 'buy', price, delta, number, this.config.fee, this.m) - (price + delta) * number / (number - (number * this.config.fee))) * 100) / 100,
        mp: this.countMeanPrice(this.uid, 'buy', price, delta, number, this.config.fee, this.m),
        m: this.m,
        number: number,
        delta: delta,
        fee: this.config.fee,
        id: Math.floor(Math.random() * 1000000)
      }
      if (!this.users[this.uid][this.acc].logs) {
        this.users[this.uid][this.acc].logs = []
      }
      this.users[this.uid][this.acc].logs.push(obj)
      this.newLog = { ...obj }
      this.updateU()
    },
    sell (number, useMoney, price, acc, uid) {
      if (this.m === '黃金') {
        this.users[uid || this.uid][acc || this.acc].gold = Math.floor((parseFloat(this.users[uid || this.uid][acc || this.acc].gold) - parseFloat(number)) * 100) / 100
        if (this.users[uid || this.uid][acc || this.acc].gold < 0) {
          this.users[uid || this.uid][acc || this.acc].gold = 0
        }
      }
      if (this.m === '白銀') {
        this.users[uid || this.uid][acc || this.acc].silver = Math.floor((parseFloat(this.users[uid || this.uid][acc || this.acc].silver) - parseFloat(number)) * 100) / 100
        if (this.users[uid || this.uid][acc || this.acc].silver < 0) {
          this.users[uid || this.uid][acc || this.acc].silver = 0
        }
      }

      let delta = this.config.delta
      if (this.m === '黃金') {
        delta = this.config.deltaSellGold
      }
      if (this.m === '白銀') {
        delta = this.config.deltaSellSilver
      }

      const money = (price - delta) * number - (price - delta) * number * this.config.fee

      const mb = this.users[uid || this.uid][acc || this.acc].money

      this.users[uid || this.uid][acc || this.acc].money += money

      if (this.users[uid || this.uid].pid && this.users[uid || this.uid].pid !== 'nobody') {
        this.taxUser(this.users[uid || this.uid].pid, acc || this.acc, money / 10, number, price, delta)
      }

      const obj = {
        time: new Date().getTime(),
        action: 'sell',
        status: 'sucuess',
        price: price,
        moneyBefore: mb,
        moneyAfter: this.users[uid || this.uid][acc || this.acc].money,
        // benifit: Math.round((price - delta) - ((price - delta) * this.config.fee) - this.countMeanPrice(uid || this.uid, 'sell', price, delta, number, this.config.fee, this.m)) * 100 / 100,
        mp: this.countMeanPrice(uid || this.uid, 'sell', price, delta, number, this.config.fee, this.m),
        m: this.m,
        number: number,
        delta: delta,
        fee: this.config.fee,
        done: true,
        id: Math.floor(Math.random() * 1000000)
      }
      if (!this.users[uid || this.uid][acc || this.acc].logs) {
        this.users[uid || this.uid][acc || this.acc].logs = []
      }
      this.users[uid || this.uid][acc || this.acc].logs.push(obj)
      this.newLog = { ...obj }
      this.updateU(uid || this.uid)
    },
    sellnull (number, useMoney, price, oldPrice, acc, uid) {
      if (this.m === '黃金') {
        this.users[uid || this.uid][acc || this.acc].gold = Math.floor((parseFloat(this.users[uid || this.uid][acc || this.acc].gold) - parseFloat(number)) * 100) / 100

        if (this.users[uid || this.uid][acc || this.acc].gold < 0) {
          this.users[uid || this.uid][acc || this.acc].gold = 0
        }
      }
      if (this.m === '白銀') {
        this.users[uid || this.uid][acc || this.acc].silver = Math.floor((parseFloat(this.users[uid || this.uid][acc || this.acc].silver) - parseFloat(number)) * 100) / 100

        if (this.users[uid || this.uid][acc || this.acc].silver < 0) {
          this.users[uid || this.uid][acc || this.acc].silver = 0
        }
      }

      const mb = this.users[uid || this.uid][acc || this.acc].money

      console.log(this.users[uid || this.uid][acc || this.acc].money)

      this.users[uid || this.uid][acc || this.acc].money += 2 * number * parseFloat(oldPrice)

      console.log(this.users[uid || this.uid][acc || this.acc].money)

      this.users[uid || this.uid][acc || this.acc].money -= useMoney

      console.log(this.users[uid || this.uid][acc || this.acc].money)

      let delta = this.config.delta
      if (this.m === '黃金') {
        delta = this.config.deltaSellGold
      }
      if (this.m === '白銀') {
        delta = this.config.deltaSellSilver
      }

      const obj = {
        time: new Date().getTime(),
        action: 'sellnull',
        status: 'sucuess',
        price: price,
        oldPrice: oldPrice,
        moneyBefore: mb,
        moneyAfter: this.users[uid || this.uid][acc || this.acc].money,
        // benifit: Math.round(this.countMeanPrice(uid || this.uid, 'sell', price, delta, number, this.config.fee, this.m) - (price + delta) - ((price + delta) * this.config.fee)) * 100 / 100,
        mp: this.countMeanPrice(uid || this.uid, 'sell', price, delta, number, this.config.fee, this.m),
        m: this.m,
        number: number,
        delta: delta,
        fee: this.config.fee,
        done: true,
        id: Math.floor(Math.random() * 1000000)
      }
      if (!this.users[uid || this.uid][acc || this.acc].logs) {
        this.users[uid || this.uid][acc || this.acc].logs = []
      }
      this.users[uid || this.uid][acc || this.acc].logs.push(obj)
      this.newLog = { ...obj }
      this.updateU(uid || this.uid)
    },
    updateConfig (c) {
      // console.log(c)
      c.delta = c.delta || this.config.delta
      c.deltaBuyGold = c.deltaBuyGold || this.config.deltaBuyGold
      c.deltaBuySilver = c.deltaBuySilver || this.config.deltaBuySilver
      c.deltaSellGold = c.deltaSellGold || this.config.deltaSellGold
      c.deltaSellSilver = c.deltaSellSilver || this.config.deltaSellSilver
      c.fee = c.fee || this.config.fee
      c.start_hour = c.start_hour || this.config.start_hour
      c.end_hour = c.end_hour || this.config.end_hour
      c.startDemoMoney = c.startDemoMoney || this.config.startDemoMoney
      c.startFormalMoney = c.startFormalMoney || this.config.startFormalMoney
      c.startMoney = c.startMoney || this.config.startMoney
      set(ref(db, 'config'), c).then(() => {
        console.log('config updated!')
      })
    },
    updateU (uid) {
      // console.log(this.email)
      const obj = {
        id: uid || this.uid,
        email: this.email,
        lev: this.users[uid || this.uid].lev || 1,
        demo: this.users[uid || this.uid].demo,
        formal: this.users[uid || this.uid].formal,
        createTime: this.users[uid || this.uid].createTime || new Date().getTime(),
        pid: this.users[uid || this.uid].pid || 'nobody',
        role: this.users[uid || this.uid].role || 'user'
      }
      if (this.users[uid || this.uid].childern) {
        obj.childern = this.users[uid || this.uid].childern
      }
      set(ref(db, 'users/' + (uid || this.uid)), obj).then(() => {
        console.log('user updated!')
      })
    },
    setChildren (id, cid) {
      const ans = { ...this.users[id] }
      ans.childern = ans.childern || []
      ans.childern.push(cid)
      set(ref(db, 'users/' + id), ans).then(() => {
        console.log('childern updated!')
      })
    },
    updateUser (id, type, number) {
      const ans = { ...this.users[id] }
      ans[type].money = parseFloat(ans[type].money) + parseFloat(number)
      set(ref(db, 'users/' + id), ans).then(() => {
        console.log('user updated!')
      })
    },
    taxUser (pid, type, benifit, number, price, delta) {
      const ans = { ...this.users[pid] }
      ans[type].money = parseFloat(ans[type].money) + parseFloat(number)
      ans[type].logs = ans[type].logs || []
      ans[type].logs.push(
        {
          time: new Date().getTime(),
          action: 'tax',
          status: 'sucuess',
          from: this.user.id,
          benifit: benifit,
          number: number,
          price: price,
          delta: delta,
          mp: price,
          done: true
        }
      )
      set(ref(db, 'users/' + pid), ans).then(() => {
        console.log('user updated!')
      })
      if (this.users[pid].pid && this.users[pid].pid !== 'nobody') {
        this.taxUser(this.users[pid].pid, type, benifit / 10, number, price, delta)
      }
    },
    toggleUser (id) {
      const ans = { ...this.users[id] }
      ans.stop = !ans.stop
      set(ref(db, 'users/' + id), ans).then(() => {
        console.log('user updated!')
      })
    },
    levelUser (id, n) {
      const ans = { ...this.users[id] }
      if (!ans.lev) { ans.lev = 0 }
      ans.lev = parseInt(ans.lev) + parseInt(n)
      set(ref(db, 'users/' + id), ans).then(() => {
        console.log('user updated!')
      })
    },
    updateLev (id, type, n) {
      const ans = { ...this.levs[id] }
      ans[type] = parseInt(ans[type]) + parseInt(n)
      set(ref(db, 'levs/' + id), ans).then(() => {
        console.log('lev updated!')
      })
    },
    toggleLev (id) {
      const ans = { ...this.levs[id] }
      ans.stop = !ans.stop
      set(ref(db, 'levs/' + id), ans).then(() => {
        console.log('lev updated!')
      })
    }
  },
  mounted () {
    const vm = this
    onValue(ref(db, 'users'), (snapshot) => {
      const data = snapshot.val()
      vm.users = data
      // vm.logged('wdpXGWEUObY7miKsK5hH5qDjkJ43', 'bestian@gmail.com')
    })
    onValue(ref(db, 'config'), (snapshot) => {
      const data = snapshot.val()
      vm.config = data
    })
    onValue(ref(db, 'levs'), (snapshot) => {
      const data = snapshot.val()
      vm.levs = data
    })
    onValue(ref(db, 'reports'), (snapshot) => {
      const data = snapshot.val()
      vm.reports = data
    })
  }
}
</script>

<style>

  html, body, #app {
    background-color: #333;
  }

.ui.menu {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media screen and (max-width: 770px) {
  .fat-only {
    display: none !important;
  }
}

.ui.dropdown .menu {
  background-color: #222 !important;
  color: white !important;
}

@media screen and (min-width: 771px) {
  .thin-only {
    display: none !important;
  }
}

.tradingview-widget-copyright {
  display: none !important;
}

.ver {
  display: flex;
  flex-direction: column;
}

.ver .up {
  color: #666;
  width: 100%;
  border-bottom: 1px solid #666;
  text-align: center;
}

.ver .down {
  color: #a33;
  text-align: center;
}

.ver .down.white {
  color: white;
  text-align: center;
}

.ui.form .field {
  padding: 0 .6em !important;
}

a {
  cursor: pointer !important;
}

.mx-input {
  margin-bottom: 2em;
  cursor: pointer;
  font-size: 18px !important;
  height: 2.6em !important;
}

.mx-icon-clear, .mx-icon-calendar {
  top: 1.5em !important;
}

.ui.visible.right.overlay.sidebar .item {
  font-size: 16px;
}

.admin i.icon:before {
  position: relative;
  left: .3em !important;
}

.ui.checkbox {
  position: relative !important;
  top: -.6em !important;
}

.ui.checkbox label {
  color: white !important;
}
</style>
