<template>
  <div class="record">
    <table v-if="newLog" class="fat-only">
      <tr>
        <th>
          {{sify('訂單號')}}
        </th>
        <th>
          {{sify('開倉時間')}}
        </th>
        <th>
          {{sify('交易品種')}}
        </th>
        <th>
          {{sify('方向')}}
        </th>
        <th>
          {{sify('交易狀態')}}
        </th>
        <th>
          {{sify('開倉價')}}
        </th>
        <th>
          {{sify('點差')}}
        </th>
        <th>
          {{sify('數量')}}
        </th>
        <th>
          {{sify('手續費')}}
        </th>
        <th>
          {{sify('持倉盈虧')}}
        </th>
        <th>
          {{sify('平倉')}}
        </th>
        <!-- <th></th> -->
      </tr>
      <tr>
        <td>
          {{newLog.id}}
        </td>
        <td>
          {{par(newLog.time)}}
        </td>
        <td>
          {{newLog.m}}
        </td>
        <td>
          {{act(newLog.action)}}
        </td>
        <td>
          {{newLog.status == 'sucuess' ? '成功' : '錯誤'}}
        </td>
        <td>
          {{pretty(newLog.price)}}
        </td>
        <td>
          {{newLog.delta}}
        </td>
        <td>
          {{newLog.number}}
        </td>
        <td>
          {{newLog.fee * 100 + '%'}}
        </td>
        <td>
          {{pretty(all)}}
        </td>
        <td>
          <a v-show="!newLog.done" class="ui button" @click="flat(newLog, acc, uid)">{{sify('平倉')}}</a>
          <p v-show="newLog.done">{{sify('已平倉')}}</p>
        </td>
        <!-- <td>
          <router-link v-show = "notHistory()" to = "/history" id="all">{{sify('查看所有交易記錄')}}
            <i class="chevron right icon"/>
          </router-link>
          <router-link v-show = "!notHistory()" to = "/" id="all">{{sify('回到主畫面')}}
            <i class="chevron right icon"/>
          </router-link>
        </td> -->
      </tr>
    </table>

    <table v-if="newLog" class="thin-only ll">
      <tr>
        <th>
          {{sify('訂單號')}}
        </th>
        <td>
          {{newLog.id}}
        </td>
      </tr>
      <tr>
        <th>
          {{sify('開倉時間')}}
        </th>
        <td>
          {{par(newLog.time)}}
        </td>
      </tr>
      <tr>
        <th>
          {{sify('交易品種')}}
        </th>
        <td>
          {{newLog.m}}
        </td>
      </tr>
      <tr>
        <th>
          {{sify('方向')}}
        </th>
        <td>
          {{act(newLog.action)}}
        </td>
      </tr>
        <th>
          {{sify('交易狀態')}}
        </th>
        <td>
          {{newLog.status == 'sucuess' ? '成功' : '錯誤'}}
        </td>
      <tr>
      </tr>
      <tr>
        <th>
          {{sify('開倉價')}}
        </th>
        <td>
          {{pretty(newLog.price)}}
        </td>
      </tr>
      <tr>
        <th>
          {{sify('點差')}}
        </th>
        <td>
          {{newLog.delta}}
        </td>
      </tr>
      <tr>
        <th>
          {{sify('數量')}}
        </th>
        <td>
          {{newLog.number}}
        </td>
      </tr>
        <th>
          {{sify('手續費')}}
        </th>
        <td>
          {{newLog.fee * 100 + '%'}}
        </td>
      <tr>
        <th>
          {{sify('持倉盈虧')}}
        </th>
        <td>
          {{pretty(all)}}
        </td>
      </tr>
      <tr v-show="!newLog.done">
        <th>
          {{sify('平倉')}}
        </th>
        <td>
          <a v-show="!newLog.done" class="ui button" @click="flat(newLog, acc, uid)">{{sify('平倉')}}</a>
        </td>
      </tr>
      <tr v-show="newLog.done">
        <th>
          {{sify('平倉')}}
        </th>
        <td>
          <p>{{sify('已平倉')}}</p>
        </td>
      </tr>
    </table>

    <!-- <div class="red list">
      {{sify('交易清單')}}
      <div class="ui list" v-if="newLog">
        <div class="l item">
          {{sify('訂單號：')}}{{newLog.id}}<br/>
          {{sify('開倉時間：')}}{{par(newLog.time)}}<br/>
          {{sify('交易品種：')}}{{newLog.m}}<br/>
          {{sify('方向：')}}{{newLog.action == 'buy' ? sify('買進') : sify('賣出')}}<br/>
          {{sify('交易狀態：')}}{{newLog.status == 'sucuess' ? '成功' : '錯誤'}}<br/>
          {{sify('開倉價：')}}{{pretty(newLog.price)}}<br/>
          {{sify('點差：')}}{{newLog.delta}}<br/>
          {{sify('數量：')}}{{newLog.number}}<br/>
          {{sify('手續費：')}}{{newLog.fee * 100 + '%'}}<br/>
          {{sify('持倉盈虧')}}：{{pretty(newLog.benifit)}}<br/>
          <router-link v-show = "notHistory()" to = "/history" id="all">{{sify('查看所有交易記錄')}}
            <i class="chevron right icon"/>
          </router-link>
          <router-link v-show = "!notHistory()" to = "/" id="all">{{sify('回到主畫面')}}
            <i class="chevron right icon"/>
          </router-link>
        </div>
      </div>
    </div>
  -->
  </div>
</template>

<script>
// @ is an alias to /src
import { sify } from 'chinese-conv'

export default {
  name: 'ReCord',
  props: ['newLog', 'cgp', 'csp', 'uid', 'acc', 'user', 'config', 'all'],
  methods: {
    act (action) {
      var ans
      if (action === 'buy') {
        ans = sify('買進')
      }
      if (action === 'buynull') {
        ans = sify('買空')
      }
      if (action === 'sell') {
        ans = sify('賣出')
      }
      if (action === 'sellnull') {
        ans = sify('賣空')
      }
      if (action === 'tax') {
        ans = sify('獎金')
      }
      if (this.newLog.done) {
        ans += sify('平倉')
      }
      return ans
    },
    flat (log, acc, uid) {
      this.$emit('flat', log, acc, uid)
    },
    countB (log) {
      if (log.action === 'buy') {
        const price = log.m === '黃金' ? this.cgp : this.csp
        return Math.round((log.mp - (price + log.delta) * log.number / (log.number - (log.number * log.fee))) * 100) / 100
      }
      if (log.action === 'sell') {
        const price = log.m === '黃金' ? this.cgp : this.csp
        return Math.round((price - log.delta) - ((price - log.delta) * log.fee) - log.mp * 100) / 100
      }
    },
    notHistory () {
      // console.log(this.$router.currentRoute.path)
      return this.$router.currentRoute.path !== '/history'
    },
    sify (t) {
      return sify(t)
    },
    pretty (n) {
      const s = '' + n
      if (s.indexOf('.') > -1) {
        return s.split('.')[0] + '.' + s.split('.')[1].substr(0, 2)
      } else {
        return s
      }
    },
    par (t, isSimple) {
      function addP (n) {
        if (('' + n).split('').length === 1) {
          return '0' + n
        } else {
          return n
        }
      }
      if (isSimple) {
        return (new Date(t).getFullYear() + '-' + (new Date(t).getMonth() + 1) + '-' + new Date(t).getDate())
      } else {
        return (addP(new Date(t).getHours()) + ':' + addP(new Date(t).getMinutes()) + ':' + addP(new Date(t).getSeconds()))
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style type="text/css" scoped>

  .record {
    width: 100%;
    padding-bottom: 2em;
  }

  .ui.form .field {
    padding: 0 3em;
  }
  .ui.form .field .label {
    background-color: #111;
    color: white;
  }
  .red.list {
    position: relative;
    border: 2px outset red;
    border-radius: 30px;
    width: 88%;
    height: 200px;
    margin: 0 auto;
  }

  .l {
    text-align: left;
  }
  #all {
    position: absolute;
    bottom: 1em;
    right: 1em;
    /* text-decoration: underline; */
  }

  table {
    border-collapse: collapse;
    width: 95%;
  }

  th {
    min-width: 60px;
    border-bottom: 2px solid #999;
    color: #999;
  }

  .ll th {
    border-right: 2px solid #999;
    border-bottom: 1px solid #999;
  }

  .ll td {
    text-align: left;
    padding-left: .6em;
    border-bottom: 1px solid #999;
  }
</style>
