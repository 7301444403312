import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import './registerServiceWorker'
import router from './router'
import './db.js'
import { rtdbPlugin, firestorePlugin } from 'vuefire'
import SuiVue from 'semantic-ui-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import JsonExcel from 'vue-json-excel'
import VueExcelXlsx from 'vue-excel-xlsx'
import '@babel/polyfill'
import 'core-js/stable'

Vue.use(VueExcelXlsx)

// Vue.component('downloadExcel', JsonExcel)
Vue.use(VueMeta)
Vue.use(VueAxios, axios)

Vue.use(SuiVue)

Vue.use(firestorePlugin)
Vue.use(rtdbPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
